import { useEffect, useReducer, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { UseUpdateSeller, useGetAcquisitionType, useGetBusinessModel, useGetFundingRaised, useGetHowManyPeopleEmployed, useGetHowValueBusiness, useGetIndustryFiltre, useGetLifecycleStage, useGetMainValueBusiness, useGetMySpecificTehnologyListing, useGetProfitability, useGetReasonListingBusiness, useGetRevenueQuarter, useGetTechnologyPartenership, useGetTimeframes } from "../api/seller";
import { useGetStaticParams } from "../api/staticParams";
import { setAcquisitionOverviewAction, setCompanyBasicsAction, setFinancialDetailsAction, setNecessaryInformationsAction } from "../pages/seller/listing/creation/redux/actions";
import { creationResellerReducer, initialState } from "../pages/seller/listing/creation/redux/reducers";
import { IAcquisitionOverview } from "../types/acquisition-overview";
import { ICompanyBasics } from "../types/company-basics";
import { IFinancialDetails } from "../types/financial-details";
import { INecessaryInformations } from "../types/necessary-informations";
interface UserSpecificTechnology {
    id: number;
    idUser: string;
    specificTechnology: any[];
}
export default function UseEditSellerCtr() {
    const queryClient = useQueryClient();

    const [showOpportunityEdit, setShowOpportunityEdit] = useState(false);
    const [showCompanyOverviewEdit, setShowCompanyOverviewEdit] = useState(false);
    const [showGoalEdit, setShowGoalEdit] = useState(false);
    const [showValueDriverEdit, setShowValueDriverEdit] = useState(false);
    const [showFinancialEdit, setShowFinancialEdit] = useState(false);
    const [allContinentSelected, setAllContinentSelected] = useState<
        Array<string>
    >([]);

    const [enabled, setEnabled] = useState(false);

    const [state, dispatch] = useReducer(creationResellerReducer, initialState);
    const [errorValueBusiness, setErrorValueBusiness] = useState(false);
    const [errorAcquirer, setErrorAcquirer] = useState(false);
    const [errorLocation, setErrorLocation] = useState(false);
    const [errorFounded, setErrorFounded] = useState(false);
    const [errorCompanySize, setErrorCompanySize] = useState(false);
    const [errorBusinessModel, setErrorBusinessModel] = useState(false);
    const [errorStage, setErrorStage] = useState(false);
    const [errorIndustry, setErrorIndustry]  = useState(false);
    const [errorSolution, setErrorSolution]  = useState(false);
    const [errorGeographic, setErrorGeographic]  = useState(false);
    const [errorTechnoPartnership, setErrorTechnoPartnership]  = useState(false);
    const [errorHaveTechnoPartnership, setErrorHaveTechnoPartnership]  = useState(false);
    const [errorStructure, setErrorStructure]  = useState(false);
    const [errorRaisedFund, setErrorRaisedFund]  = useState(false);
    const [errorQuarterRevenue, setErrorQuarterRevenue]  = useState(false);
    const [errorProfitability, setErrorProfitability]  = useState(false);
    const [errorCompanyReason, setErrorCompanyReason]  = useState(false);
    const [errorExpCompanyReason, setErrorExpCompanyReason]  = useState(false);
    const [errorAcquisitionType, setErrorAcquisitionType]  = useState(false);
    const [errorTransaction, setErrorTransaction]  = useState(false);
    const [errorSummarize, setErrorSummarize]  = useState(false);
    const [errorMainValue, setErrorMainValue]  = useState(false);
    const [errorKeyAssets, setErrorKeyAssets]  = useState(false);
    const [errorTargetAudience, setErrorTargetAudience]  = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [technologiesPartnership, setTechnologiesPartnership] = useState<Array<{ value: number; label: string }>>([]);
    const [userSpecificTechnologies, setUserSpecificTechnologies] = useState<Array<UserSpecificTechnology>>([]);


    const setAcquisitionOverview = (data: IAcquisitionOverview) => {
        dispatch(setAcquisitionOverviewAction(data));
    };
    
    const setNecessaryInformations = (data: INecessaryInformations) => {
        dispatch(setNecessaryInformationsAction(data));
    };

    const setCompanyBasics = (data: ICompanyBasics) => {
        dispatch(setCompanyBasicsAction(data));
    };

    const setFinancialDetails = (data: IFinancialDetails) => {
        dispatch(setFinancialDetailsAction(data));
    };

    const { data: dataCountry } = useGetStaticParams('COUNTRIES_SORT_NAME', enabled);

    const { data: dataHowManyPeople } = useGetHowManyPeopleEmployed(enabled);
    const { data: dataBusinessModel } = useGetBusinessModel(enabled);
    const { data: dataLifecycle } = useGetLifecycleStage(enabled);
    const { data: dataHowValueBusiness } = useGetHowValueBusiness(enabled);
    const { data: dataIndustries } = useGetIndustryFiltre(enabled);
    const { data: dataTechnologyPartenership } = useGetTechnologyPartenership(enabled);
    const { data: dataReasonListing } = useGetReasonListingBusiness(enabled);
    const { data: dataAcquisitions } = useGetAcquisitionType(enabled);
    const { data: dataTimeframes } = useGetTimeframes(enabled);
    const { data: dataMainValueBusiness } = useGetMainValueBusiness(enabled);
    const { data: dataFundingRaised } = useGetFundingRaised(enabled);
    const { data: dataRevenue } = useGetRevenueQuarter(enabled);
    const { data: dataProfitabilityBusiness } = useGetProfitability(enabled);
    const { data: userSpecificTechnologie } = useGetMySpecificTehnologyListing(enabled);

    const mutUpdate = useMutation({mutationFn:UseUpdateSeller, 
        retry: 0,
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries();
        }
    });

    useEffect(() => {
        if (userSpecificTechnologie) {
            let specificTech: Array<UserSpecificTechnology> = [];
            userSpecificTechnologie.forEach((item: UserSpecificTechnology) => {
                specificTech = specificTech.concat(item.specificTechnology);
            });
            setUserSpecificTechnologies(specificTech);
        }
    }, [userSpecificTechnologie]);

    useEffect(() => {
        if (dataTechnologyPartenership && dataTechnologyPartenership.data) {
            let combinedData = [];

            const mappedData = dataTechnologyPartenership.data.map((item: any) => ({
                value: item.id,
                label: item.attributes.Label
            }));

            combinedData = [...mappedData];

            if (userSpecificTechnologies.length > 0) {
                const lastEcosystemDataId = dataTechnologyPartenership.data[dataTechnologyPartenership.data.length - 1].id;
                const transformedUserSpecificTechnologies = userSpecificTechnologies.map((item, index) => ({
                    value: lastEcosystemDataId + index + 1,
                    label: item
                }));
                combinedData = [...combinedData, ...transformedUserSpecificTechnologies];
            }

            setTechnologiesPartnership(combinedData);
        }
    }, [dataTechnologyPartenership, userSpecificTechnologies]);

    useEffect(() => { 
        if(showCompanyOverviewEdit || showFinancialEdit || showGoalEdit || showOpportunityEdit || showValueDriverEdit || userSpecificTechnologie) setEnabled(true);
        if(dataAcquisitions && 
            dataBusinessModel && 
            dataCountry && 
            dataFundingRaised && 
            dataHowManyPeople && 
            dataHowValueBusiness && 
            dataIndustries && 
            dataLifecycle && 
            dataMainValueBusiness && 
            dataProfitabilityBusiness && 
            dataReasonListing && 
            dataRevenue && 
            dataTechnologyPartenership && 
            dataTimeframes && 
            userSpecificTechnologie) setEnabled(false);
    }, [showCompanyOverviewEdit, 
        showFinancialEdit, 
        showGoalEdit, 
        showOpportunityEdit, 
        showValueDriverEdit,
        dataAcquisitions, 
        dataBusinessModel,
        dataCountry,
        dataFundingRaised,
        dataHowManyPeople,
        dataHowValueBusiness,
        dataIndustries,
        dataLifecycle,
        dataMainValueBusiness,
        dataProfitabilityBusiness,
        dataReasonListing,
        dataRevenue,
        dataTechnologyPartenership,
        dataTimeframes,
        userSpecificTechnologie
    ])
    

    useEffect(() => {
        setErrorValueBusiness(false);
    }, [state.acquisition_overview.business_value]);

    useEffect(() => {
        setErrorAcquirer(false);
    }, [state.necessary_informations.acquirer_interest]);

    useEffect(() => {
        setErrorLocation(false);
    }, [state.company_basics.location]);

    useEffect(() => {
        setErrorFounded(false);
    }, [state.company_basics.founded]);

    useEffect(() => {
        setErrorCompanySize(false);
    }, [state.company_basics.company_size]);

    useEffect(() => {
        setErrorBusinessModel(false);
    }, [state.company_basics.business_model]);

    useEffect(() => {
        setErrorStage(false);
    }, [state.necessary_informations.lifecycle_stage]);

    useEffect(() => {
        setErrorIndustry(false);
    }, [state.company_basics.targeted_industry]);

    useEffect(() => {
        setErrorSolution(false);
    }, [state.company_basics.software_solution, state.company_basics.featured_solution]);

    useEffect(() => {
        setErrorGeographic(false);
    }, [state.company_basics.geographic_sales_footprint]);

    useEffect(() => {
        setErrorTechnoPartnership(false);
    }, [state.company_basics.technology_ecosystem]);

    useEffect(() => {
        setErrorStructure(false);
    }, [state.financial_details.ownership_structure]);

    useEffect(() => {
        setErrorRaisedFund(false);
    }, [state.financial_details.raised_funds]);

    useEffect(() => {
        setErrorQuarterRevenue(false);
    }, [state.financial_details.last_quarter_revenue]);

    useEffect(() => {
        setErrorProfitability(false);
    }, [state.financial_details.profitability]);

    useEffect(() => {
        setErrorCompanyReason(false);
    }, [state.acquisition_overview.reason]);

    useEffect(() => {
        setErrorExpCompanyReason(false);
    }, [state.acquisition_overview.explication]);

    useEffect(() => {
        setErrorAcquisitionType(false);
    }, [state.acquisition_overview.acquisition_type]);

    useEffect(() => {
        setErrorTransaction(false);
    }, [state.acquisition_overview.transaction_duration]);

    useEffect(() => {
        setErrorSummarize(false);
    }, [state.necessary_informations.summarize]);

    useEffect(() => {
        setErrorMainValue(false);
    }, [state.necessary_informations.main_value]);

    useEffect(() => {
        setErrorKeyAssets(false);
    }, [state.necessary_informations.key_assets]);

    useEffect(() => {
        setErrorTargetAudience(false);
    }, [state.necessary_informations.target_audience]);

    useEffect(() => {
        setErrorHaveTechnoPartnership(false);
    }, [state.company_basics.have_technology]);

    let dataIndustry = dataIndustries?.data || [];
    let divider = Math.floor(dataIndustry?.length / 3);

    let firstColumnIndustry = [];
    let secondColumnIndustry = [];
    let thirdColumnIndustry = [];

    if (dataIndustry.length > 0 && divider > 0) {
        firstColumnIndustry = dataIndustry.slice(0, divider);
        secondColumnIndustry = dataIndustry.slice(divider, divider * 2);
        thirdColumnIndustry = dataIndustry.slice(divider * 2); 
    }

    const geographicData = dataCountry && dataCountry.data ? dataCountry?.data?.reduce((acc: any, current: any) => {
        if (!current || !current.attributes) {
            return acc;
        }
        const continent = current?.attributes?.Continent;
        const countryInfo = {
            ISO2: current?.attributes?.ISO2,
            Name: current?.attributes?.Name,
        };

        if(!acc[continent]) {
            acc[continent] = {
                Continent: continent,
                Countries: [countryInfo],
            };
        } else {
            acc[continent].Countries.push(countryInfo);
        }

        return acc;

    }, {}) : {};

    const transformDataCountry = (data: any) => {
        let custom = data.countryesData.map((el: any) => {
            let temp = {
                continent: data?.continent,
                country: el && el?.Name,
                iso2: el && el?.ISO2,
            };
            return temp;
        });
        return custom;
    }

    const transforStateGeographic = (data: any) => {
        let custom = data.map((el: any) => {
            let temp = {
                continent: el?.council_region,
                country: el?.country,
                iso2: el?.iso2,
            };
            return temp;
        });
        return custom;
    }

    const transforStateTechnology = (data: any) => {
        let custom = data.map((el: any) => {
            let temp = {
                label: el,
                value: el
            };
            return temp;
        });
        return custom;
    }

    const transformStateRevenue = (data: any) => {
        let custom = data.map((el: any) => {
            let temp = el?.amount_value;
            return temp;
        });

        return custom;
    }

    const updateSeller = async (idSeller: any) => {

        let techno: any[] = [];
        let revenues: any[] = [];
        let profits: any[] = [];

        const currentYear = new Date().getFullYear();
        const recentYears = [currentYear, currentYear-1, currentYear-2];

        state?.company_basics?.technology_ecosystem.forEach((eco: any) => {
            techno.push(eco?.value);
        });

        for (let i = 0; i < recentYears.length; i++) {
            const year = recentYears[i];
            let totalRevenue = state?.financial_details?.[`total_revenue${i + 1}`];
            let totalProfit = state?.financial_details?.[`total_profit${i + 1}`];
          
            totalRevenue = totalRevenue !== undefined && totalRevenue !== "" ? totalRevenue : 0;
            totalProfit = totalProfit !== undefined && totalProfit !== "" ? totalProfit : 0;
      
            const revenueString = `${year}-${totalRevenue}`;
            const profitString = `${year}-${totalProfit}`;
      
            revenues.push(revenueString);
            profits.push(profitString);
        }

        let dataParam = {
            idSeller,

            reason_listing: state?.acquisition_overview?.reason,
            desc_reason_listing: state?.acquisition_overview?.explication,
            desired_acquisition: state?.acquisition_overview?.acquisiton_type,
            urgency: state?.acquisition_overview?.transaction_duration,
            valuation: state?.acquisition_overview?.business_value,

            opportunity: state?.necessary_informations?.summarize,
            stage: state?.necessary_informations?.lifecycle_stage,
            value_driver: state?.necessary_informations?.main_value,
            key_assets: state?.necessary_informations?.key_assets,
            target_audience: state?.necessary_informations?.target_audience,
            top_competitors: state?.necessary_informations?.top_competitors,
            company_reason: state?.necessary_informations?.acquirer_interest,

            location: state?.company_basics?.location,
            company_size: state?.company_basics?.company_size,
            founded: state?.company_basics?.founded,
            business_model: state?.company_basics?.business_model,
            have_technology: state?.company_basics?.have_technology,
            technology_partnership: techno,
            targeted_industry: state?.company_basics?.targeted_industry,
            software_solution: state?.company_basics?.software_solution,
            featured_solution: state?.company_basics?.featured_solution,
            geographic_footprint: state?.company_basics?.geographic_sales_footprint,

            structure_ownership: state?.financial_details?.ownership_structure,
            raised_funds: state?.financial_details?.raised_funds,
            quarter_revenue: state?.financial_details?.last_quarter_revenue,
            profitability: state?.financial_details?.profitability,
            revenue_generated: revenues,
            profit_made: profits,
        }

        const response = await mutUpdate.mutateAsync(dataParam);

        return response?.data;
    }

    return {
        showCompanyOverviewEdit,
        showFinancialEdit,
        showGoalEdit,
        showOpportunityEdit,
        showValueDriverEdit,
        state,
        setShowCompanyOverviewEdit,
        setShowFinancialEdit,
        setShowGoalEdit,
        setShowOpportunityEdit,
        setShowValueDriverEdit,
        setAcquisitionOverview,
        setCompanyBasics,
        setNecessaryInformations,
        setFinancialDetails,
        dataCountry,
        dataHowManyPeople,
        dataBusinessModel,
        dataLifecycle,
        dataHowValueBusiness,
        firstColumnIndustry,
        secondColumnIndustry,
        thirdColumnIndustry,
        geographicData,
        transformDataCountry,
        allContinentSelected,
        setAllContinentSelected,
        dataTechnologyPartenership,
        dataReasonListing,
        dataAcquisitions,
        dataTimeframes,
        dataMainValueBusiness,
        dataFundingRaised,
        dataRevenue,
        dataProfitabilityBusiness,
        updateSeller,
        errorValueBusiness,
        setErrorValueBusiness,
        errorAcquirer,
        setErrorAcquirer,
        errorLocation,
        setErrorLocation,
        errorFounded,
        setErrorFounded,
        errorCompanySize,
        setErrorCompanySize,
        errorBusinessModel,
        setErrorBusinessModel,
        errorStage,
        setErrorStage,
        errorIndustry,
        setErrorIndustry,
        errorSolution,
        setErrorSolution,
        errorGeographic,
        setErrorGeographic,
        errorTechnoPartnership,
        setErrorTechnoPartnership,
        errorStructure,
        setErrorStructure,
        errorRaisedFund,
        setErrorRaisedFund,
        errorQuarterRevenue,
        setErrorQuarterRevenue,
        errorProfitability,
        setErrorProfitability,
        errorCompanyReason,
        setErrorCompanyReason,
        errorExpCompanyReason,
        setErrorExpCompanyReason,
        errorAcquisitionType,
        setErrorAcquisitionType,
        errorTransaction,
        setErrorTransaction,
        errorSummarize,
        setErrorSummarize,
        errorMainValue,
        setErrorMainValue,
        errorKeyAssets,
        setErrorKeyAssets,
        errorTargetAudience,
        setErrorTargetAudience,
        transforStateGeographic,
        transforStateTechnology,
        isLoading,
        setIsLoading,
        transformStateRevenue,
        setErrorHaveTechnoPartnership,
        userSpecificTechnologie,
        technologiesPartnership,
        setTechnologiesPartnership,
    }
}