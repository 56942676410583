import ISO3166 from 'iso-3166-1-alpha-2';
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useGetMyBuyerListing } from "../../../../api/buyer";
import { UseGetMySellerListing } from "../../../../api/seller";
import { useGetTypeUser } from "../../../../api/user";
import { useKeycloakHooks } from "../../../../hooks/keycloak";
import SellerSecondMenu from "../../../../layouts/second-headers/seller";
import { LoaderCommon } from "../../../../widget/loader";
import "../../seller.scss";

export default function MySellerListing() {
    const navigate = useNavigate();

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const [enabledMySellerListing, setEnabledMySellerListing] = useState(false);
    const { data, isLoading: mySellerListingsLoading, isFetched: isMySellerListingFetched } = UseGetMySellerListing(enabledMySellerListing);

    const [enabled, setEnabled] = useState(false);
    const { data: userRoles, isLoading: userRolesLoading } = useGetTypeUser(tokenInfo, enabled);

    const [enabledGetMyBuyerListing, setEnabledGetMyBuyerListing] = useState(false);
    const { data: myBuyerListing, isFetched: isMyBuyerListingFetched } = useGetMyBuyerListing(enabledGetMyBuyerListing);

    useEffect(() => {
        if(tokenInfo?.user_uuid) setEnabled(true);
        if(userRoles && myBuyerListing) setEnabled(false);
    }, [tokenInfo?.user_uuid, userRoles])

    useEffect(() => {
        if(isMySellerListingFetched) setEnabledMySellerListing(false);
    }, [isMySellerListingFetched])

    useEffect(() => {
        if(isMyBuyerListingFetched) setEnabledGetMyBuyerListing(false);
    }, [isMyBuyerListingFetched])

    const handleGoToFavorite = () => {
        if(keycloak?.authenticated){}
        else keycloak?.login();
    }

    const goToDetailListing = () => {
        data && data.seller && navigate(`/seller/listing/details/${data.seller?.id}`)
    }
    
    const isOpportunistic = (el: any) => {
        var isOppo = false ;
    
        if(el?.urgency === "Opportunistic" || el?.reason_listing === "I'm opportunistic and open to receive inquiries from potential buyers") {
            isOppo = true
        }
    
        return isOppo;
    }
    
    const isPremium = (el: any) => {
        var isPrem = false;
        if(el?.user_account?.is_premium) {
            isPrem = true;
        }
    
        return isPrem;
    }

    const goToMyBuyerListing = () => {
        if(userRoles && userRoles.isBuyer) {
            navigate(`/buyer/listing/my-listing`);
        }
    };

    return (
        <>
            {(!initialized || mySellerListingsLoading || userRolesLoading) &&
                <div className='loaderCommon'>
                <LoaderCommon />
                </div>
            }
            {(initialized && !keycloak?.authenticated) && <Navigate to="/" />}
            {
                (initialized && keycloak?.authenticated && !mySellerListingsLoading && data && !userRolesLoading) && (
                    <div className="findIsvcontainer programContainer sellerContainer">
                        <SellerSecondMenu
                            showSearch={false}
                            handleGoToFavorite={handleGoToFavorite}
                        />

                        <div className="filterFindIsv leftTop hScroll">
                            <div className="contentFilter mt-0">
                                <div className="block sellerBuyer pb-0 border-0">
                                    <div className="sellers active">
                                        <a href="javascript:;">
                                            <span className="icon-sellers"></span>My seller listing
                                        </a>
                                    </div>
                                    <div className={`buyer ${userRoles && !userRoles.isBuyer ? 'my-listing-disabled' : ''}`} onClick={() => goToMyBuyerListing()}>
                                        <a href="javascript:;">
                                            <span className="icon-buyerList"></span>My buyer listing
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mx-auto minHeightwin sellerRight">
                            <div style={{paddingTop:"7.5rem"}} id="my-listing" className="listOfIsv listOfSeller scrollContainer">
                                <ul className="listSeller">
                                    {data && data.seller && (
                                        <li className={`list ${isOpportunistic(data.seller) ? 'opportunistic' : ''} ${isPremium(data.seller) ? 'premium' : ''}`.trim()}>
                                            <div className="logo">
                                                <span className="icon-building1"></span>
                                            </div>
                                            <div className="desc">
                                                <h2 className="title">
                                                {data.seller?.stage} <span className="icon-star-off"></span>
                                                </h2>
                                                <div className="about"><span>{data.seller?.company_reason}</span></div>
                                                <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetailListing()}>More details</a>
                                                <ul>
                                                <li className="item" key={"el_location"}>
                                                    <div>Location</div>
                                                    <span className="ico icon-location"></span>
                                                    <span className="txt">{ISO3166.getCountry(data.seller?.location)}</span>
                                                </li>
                                                <li className="item" key={"el_founded"}>
                                                    <div>Founded</div>
                                                    <span className="ico icon-founded"></span>
                                                    <span className="txt">{data.seller?.founded}</span>
                                                </li>
                                                <li className="item" key={"el_companySIze"}>
                                                    <div>Company size</div>
                                                    <span className="ico icon-compSize"></span>
                                                    <span className="txt">{data.seller?.company_size}</span>
                                                </li>
                                                <li className="item" key={"el_value_driver"}>
                                                    <div>Top value driver</div>
                                                    <span className="ico icon-topValue"></span>
                                                    <span className="txt">{data.seller?.value_driver}</span>
                                                </li>
                                                <li className="item" key={"el_valuation"}>
                                                    <div>Valuation</div>
                                                    <span className="ico icon-valuation"></span>
                                                    <span className="txt">{data.seller?.valuation}</span>
                                                </li>
                                                <li className="item" key={"el_profitability"}>
                                                    <div>Profitability</div>
                                                    <span className="ico icon-profitability"></span>
                                                    <span className="txt">{data.seller?.profitability}</span>
                                                </li>
                                                </ul>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
            
        </>
    )
}