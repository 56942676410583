import ISO3166 from 'iso-3166-1-alpha-2';
import { Base64 } from 'js-base64';
import { useEffect, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UseDeleteBuyerListing, useGetMyBuyerListing } from '../../../../api/buyer';
import { urlsApi } from "../../../../api/urls/apiUrl";
import { useKeycloakHooks } from '../../../../hooks/keycloak';
import DollarImg from '../../../../resources/images/dollar.svg';
import Img from '../../../../resources/images/img-preview.svg';
import { scrollToTop } from '../../../../utils/dom';
import { getRoleDisplayName } from '../../../../utils/role-mapping';
import { LoaderCommon, LoadingAllPage } from '../../../../widget/loader';
import '../../buyer.scss';
import "./info-creation.scss";

const PreviewBuyer = () => {
    const navigate = useNavigate();

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const [enabled, setEnabled] = useState(false);

    const { data, isFetched: isMyListingFetched, isLoading: isMyListingLoading, refetch } = useGetMyBuyerListing(enabled);

    useEffect(() => {
        scrollToTop();
    }, [])

    useEffect(() => {
        if (tokenInfo?.user_uuid) setEnabled(true);
        if (isMyListingFetched) setEnabled(false);
    }, [tokenInfo?.user_uuid, isMyListingFetched])

    const goToHomePage = () => {
        navigate("/");
    };
    const [imageSrc, setImageSrc] = useState<string>('');
    const base64String = data?.company_image;

    useEffect(() => {
        if (base64String) {
            const imageSrcs = Base64.decode(base64String);
            setImageSrc(imageSrcs);
        }
    }, [base64String]);

    useEffect(() => {
        if (initialized && !keycloak.authenticated) goToHomePage();
    }, [keycloak.authenticated, keycloak.token]);

    const isMyDetailListing = data?.id_user === tokenInfo?.sub;

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [activeId, setActiveId] = useState<any>("null");

    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    }

    const mutDelete = useMutation({
        mutationFn: UseDeleteBuyerListing,
        onSuccess: (data) => {
            if (data.status === 200) {
                refetch();
                setShowDeleteModal(!showDeleteModal);
            } else {
                refetch();
                setShowDeleteModal(!showDeleteModal);
            }
        }
    })

    const geographicData = data && data?.geographic_buyer?.country ? data?.geographic_buyer?.country?.reduce((acc: any, current: any) => {
        if (!current) {
            return acc;
        }
        const continent = current?.continent;
        const countryInfo = {
            ISO2: current?.iso2,
            Name: current?.country,
        };

        if (!acc[continent]) {
            acc[continent] = {
                Continent: continent,
                Countries: [countryInfo],
            };
        } else {
            acc[continent].Countries.push(countryInfo);
        }

        return acc;

    }, {}) : {};

    const disabledClass = data?.is_removed ? 'disabled' : '';

    const handleExitPreview = () => {
        // navigate(`/buyer/listing/details/${data?.id}`);
        window.close();
    };

    const [activeSection, setActiveSection] = useState('M&A intentions');

    const maIntentionRef = useRef(null);
    const companyOverviewRef = useRef(null);
    const acquisitionRef = useRef(null);
    const maContactRef = useRef(null);

    const handleClickMenu = (ref: any, section: string) => {
        const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
        const adjustment = 130; // Ajustez cette valeur en fonction de vos besoins
        window.scrollTo({
            top: elementPosition - adjustment,
            behavior: 'smooth'
        });
        setActiveSection(section);
    };

    return (
        <>
            {!initialized && <LoadingAllPage/>}
            {initialized && (
                <div className='buyer' id='preview'>
                    {isMyListingLoading && <div className='forBillingCenter pt-3'> <div className="topCenter"><LoaderCommon /></div></div>}
                    {isMyListingFetched &&
                        <>
                            <div className='contentBorder formForEmail'>
                                <button
                                    style={{ marginLeft: "1rem" }}
                                    className='btn btn-devinsider px-5 cancel'
                                    onClick={() => handleExitPreview()}
                                >
                                    Exit preview
                                </button>
                            </div>
                            <div className="findIsvcontainer programContainer sellerContainer">
                                <div className='filterFindIsv previewFixed hScroll'>
                                    <div className='contentFilter'>
                                        <div className='matchFilter'>
                                            <ul className=''>
                                                <li className='d-flex align-items-center flex-column radius p-0 m-0' key={"match"}>
                                                    <>
                                                        {/* {
                                                            data?.company_image !== null && data?.company_image !== undefined && data?.company_image !== "" ? (
                                                                <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + data.company_image}
                                                                    width={60}
                                                                    height={60}
                                                                    alt="Company Logo" />
                                                            ) : ( */}
                                                                <span className='icon-building ico'></span>
                                                            {/* )
                                                        } */}
                                                    </>
                                                    {
                                                        isMyDetailListing && (
                                                            <div className='match m-0'>
                                                                <span>match</span>
                                                                <span>100%</span>
                                                            </div>
                                                        )
                                                    }
        
                                                </li>
                                                <li className='d-inline-flex' key={"stage"}>
                                                    <ul className='d-flex flex-column'>
                                                        <li className='d-flex'>
                                                            {data?.userAccount.is_premium === true ? `Motivated Buyer` : `Opportunistic Buyer`}
                                                        </li>
                                                        <li style={{ color: "#ff6600" }} className='d-flex m-0 fs-6'>
                                                            <svg style={{ marginRight: "0.3rem" }} width="7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                                <path fill="#ff6600" d="M296 160H180.6l42.6-129.8C227.2 15 215.7 0 200 0H56C44 0 33.8 8.9 32.2 20.8l-32 240C-1.7 275.2 9.5 288 24 288h118.7L96.6 482.5c-3.6 15.2 8 29.5 23.3 29.5 8.4 0 16.4-4.4 20.8-12l176-304c9.3-15.9-2.2-36-20.7-36z" />
                                                            </svg>
                                                            {tokenInfo && getRoleDisplayName(tokenInfo.roles)}
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
        
                                            {
                                                isMyDetailListing && (
                                                    <div className='text-center status' key={"status"}><span>Status:</span> <span style={{ color: "#666" }}><svg style={{ verticalAlign: "sub", marginRight: "0.3rem" }} width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-2">
                                                        <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clipRule="evenodd" />
                                                    </svg>{data?.is_published === true ? `Locked` : `Locked`}</span></div>
                                                )
                                            }
        
        
                                        </div>
                                        <button
                                            className='btn btn-devinsider px-5 mt-3'
                                        >
                                            Unlock ($75 USD)
                                        </button>
        
                                        <button
                                            className='btn btn-devinsider px-5 cancel btn-disabled'
                                        >
                                            <svg style={{ marginRight: "0.3rem" }} width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-2">
                                                <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clipRule="evenodd" />
                                            </svg>
        
                                            Send a message
                                        </button>
                                        <div className={`block titleLeft border-0 ${activeSection === 'M&A intentions' ? 'active' : ''}`} onClick={() => handleClickMenu(maIntentionRef, 'M&A intentions')}>
                                            <span className="txtL">1</span> <span className='txt'>M&A intentions</span>
                                        </div>
                                        <div className={`block titleLeft border-0 ${activeSection === 'Company overview' ? 'active' : ''}`} onClick={() => handleClickMenu(companyOverviewRef, 'Company overview')}>
                                            <span className="txtL">2</span> <span className='txt'>Company overview</span>
                                        </div>
                                        <div className={`block titleLeft border-0 ${activeSection === 'Acquisition target characteristics' ? 'active' : ''}`} onClick={() => handleClickMenu(acquisitionRef, 'Acquisition target characteristics')}>
                                            <span className="txtL">3</span> <span className='txt'>Acquisition target characteristics</span>
                                        </div>
                                        <div className={`block titleLeft border-0 ${activeSection === 'M&A contact details' ? 'active' : ''}`} onClick={() => handleClickMenu(maContactRef, 'M&A contact details')}>
                                            <span className="txtL">4</span> <span className='txt'>M&A contact details </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='container mx-auto minHeightwin sellerRight'>
                                    <div className='listOfIsv completePage'>
                                        <div className='scrollContainer'>
                                            <div className={`contentUser `} ref={maIntentionRef}>
                                                <h2 className='title'>M&A Intentions</h2>
                                                <span className={`txt ${disabledClass}`}>
                                                    {data?.ma_intention}
                                                </span>
                                                <h2 className='title small'>Intended acquisitions in the next 12 mounths</h2>
                                                <div className='listTopic'>
                                                    <span className={`txt topic ${disabledClass}`}>
                                                        {data?.challenge}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={`contentUser `} ref={companyOverviewRef}>
                                                <h2 className='title'>Company overview</h2>
                                                <span className='txt'>
                                                    {data?.company_reason}
                                                </span>
                                                <div className='details'>
                                                    <ul className='listDetails'>
                                                        <li className="item" key={"location"}>
                                                            <span className="ico icon-location"></span>
                                                            <div>Location</div>
                                                            <span className={`txt ${disabledClass}`}>  {ISO3166.getCountry(data?.location)}</span>
                                                        </li>
                                                        <li className="item" key={"founded"}>
                                                            <span className="ico icon-founded"></span>
                                                            <div>Founded</div>
                                                            <span className={`txt ${disabledClass}`}>  {data?.founded}</span>
                                                        </li>
                                                        <li className="item" key={"companySize"}>
                                                            <span className="ico icon-compSize"></span>
                                                            <div>Company size</div>
                                                            <span className={`txt ${disabledClass}`}>  {data?.company_size}</span>
                                                        </li>
                                                        <li className="item" key={"recent_acquisition"}>
                                                            <span className="ico icon-location"></span>
                                                            <div>Recent acquisition</div>
                                                            <span className={`txt ${disabledClass}`}>  {data?.recent_acquisition}</span>
                                                        </li>
                                                        <li className="item" key={"main_acquisition"}>
                                                            <span className="ico icon-location"></span>
                                                            <div>Main acquisition goal </div>
                                                            <span className={`txt ${disabledClass}`}>  {data?.main_acquisition_goal}</span>
                                                        </li>
                                                        <li className="item" key={"budget_indication"}>
        
                                                            {
                                                                data?.is_removed == false && data?.ma_budget_indication !== "manda_deleted_manda" ? (
                                                                    <>
                                                                        <span className="">
                                                                            <img src={DollarImg} width={40} height={30} />
                                                                        </span>
                                                                        <div>M&A budget indication</div>
                                                                        <span className="txt">
                                                                            {data?.ma_budget_indication !== "" ? data?.ma_budget_indication : "-"}
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <img
                                                                        src={Img}
                                                                        width={50}
                                                                        height={40}
                                                                    />
                                                                )
                                                            }
        
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h2 className='title small'>Software solution (Horizontal)</h2>
                                                    <ul className='listTopic'>
                                                        {([...data?.software_solution, ...data?.featured_solution] || []).filter((el, index, self) => self.indexOf(el) === index).map((el: any, index: number) => (
                                                            <span key={index} className="topic">{el}</span>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h2 className='title small'>Targeted Industry (Vertical)</h2>
                                                    <ul className='listTopic'>
                                                        {
                                                            data?.targeted_industry?.map((el: any) => (
                                                                <>
                                                                    <span className={`topic ${disabledClass}`}>  {el}</span>
                                                                </>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h2 className='title small'>Geographic sales footprint</h2>
                                                    {!data?.is_removed ? (
                                                        <div className="geographicList border-0">
                                                            <Accordion defaultActiveKey={activeId}>
                                                                <div className="geographicList">
                                                                    <Accordion defaultActiveKey={activeId}>
                                                                        {
                                                                            geographicData && Object.values(geographicData)?.map((item: any, index: number) => {
                                                                                return (
                                                                                    <div
                                                                                        className={
                                                                                            activeId == index.toString()
                                                                                                ? "panel-wrap active-panel"
                                                                                                : "panel-wrap"
                                                                                        }
                                                                                        key={index}
                                                                                    >
                                                                                        <Accordion.Item eventKey={index.toString()}>
                                                                                            <div className="panel-header">
                                                                                                <Accordion.Header
                                                                                                    onClick={() => toggleActive(index.toString())}
                                                                                                    className="panel-toggle selectedItem"
                                                                                                >
                                                                                                    <span className="topic">    {item && item?.Continent}</span>
                                                                                                </Accordion.Header>
                                                                                            </div>
        
                                                                                            <Accordion.Body>
                                                                                                {
                                                                                                    item &&
                                                                                                    item?.Countries &&
                                                                                                    item?.Countries?.map((pays: any, key: number) => {
                                                                                                        return (
                                                                                                            <div className="panel-body" key={key}>
                                                                                                                <span className="topic">
                                                                                                                    {pays?.Name}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Accordion.Body>
                                                                                        </Accordion.Item>
        
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Accordion>
        
                                                                </div>
                                                            </Accordion>
                                                        </div>
                                                    ) : (
                                                        <span className="txt"><Skeleton count={1.5} /></span>
                                                    )}
                                                </div>
                                                {/* {data?.ecosystem?.length > 0 && (
                                                    <div>
                                                        <h2 className='title small'>Technology partnerships</h2>
                                                        {data?.is_removed == false && !data?.ecosystem?.find((el: any) => el === "manda_deleted_manda") ? (
                                                            <ul className='listTopic'>
                                                                {data.ecosystem.map((el: any) => (
                                                                    <span className="topic"> {el}</span>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <span className="txt"><Skeleton count={1.5} /></span>
                                                        )}
                                                    </div>
                                                )} */}
                                            </div>
                                            <div className={`contentUser`} ref={acquisitionRef}>
                                                <h2 className='title'>Acquisition target characteristics</h2>
                                                <div>
                                                    <h2 className='title small'>Preferred ISV life cycle stage</h2>
                                                    <ul className='listTopic'>
                                                        {
                                                            data?.lifecycle_stage?.map((el: any) => (
                                                                <>
                                                                    <span className={`topic ${disabledClass}`}>{el}</span>
                                                                </>
                                                            ))
                                                        }
                                                    </ul>
                                                    <div>
                                                        <h2 className='title small'>Preferred software solutions to acquire (Horizontal)</h2>
                                                        <ul className='listTopic'>
                                                            {([...data?.preferred_software, ...data?.preferred_featured_software] || []).filter((el, index, self) => self.indexOf(el) === index).map((el: any, index: number) => (
                                                                <span key={index} className="topic">{el}</span>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h2 className='title small'>Preferred industries (Vertical)</h2>
                                                        <ul className='listTopic'>
                                                            {
                                                                data?.preferred_industries?.map((el: any) => (
                                                                    <>
                                                                        <span className={`topic ${disabledClass}`}>{el}</span>
                                                                    </>
                                                                ))
                                                            }
                                                        </ul>
                                                        <h2 className='title small'>Preferred ISV HQ location</h2>
                                                        <ul className="listTopic">
                                                            {
                                                                data?.preferred_hq_location.map((hqLocation: any, i: number) => (
                                                                    <span className={`topic ${disabledClass}`} key={i}>
                                                                        {ISO3166.getCountry(hqLocation.iso2)}
                                                                    </span>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`contentUser contactDetails`} ref={maContactRef}>
                                                <div>
                                                    <h2 className='title'> <svg style={{ marginRight: "0.3rem" }} width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-2">
                                                        <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clipRule="evenodd" />
                                                    </svg>
                                                        M&A Contact details</h2>
                                                </div>
                                                <div>
                                                    <h2 className='title small'>Contact person</h2>
                                                    {
                                                        !data?.is_removed ? (
                                                            <ul className='listTopic'>
                                                                <span style={{ color: "#64b9da" }} className="topic">[Hidden Information]</span>
                                                            </ul>
                                                        ) : (
                                                            <span className="txt"><Skeleton count={1.5} /></span>
                                                        )
                                                    }
                                                    <div>
                                                        <h2 className='title small'>Linkedin url</h2>
                                                        {
                                                            !data?.is_removed ? (
                                                                <ul className='listTopic'>
                                                                    <span style={{ color: "#64b9da" }} className="topic">[Hidden Information]</span>
                                                                </ul>
                                                            ) : (
                                                                <span className="txt"><Skeleton count={1.5} /></span>
                                                            )
                                                        }
        
                                                    </div>
        
                                                    <div>
                                                        <h2 className='title small'>Email</h2>
                                                        {
                                                            !data?.is_removed ? (
                                                                <ul className='listTopic'>
                                                                    <span style={{ color: "#64b9da" }} className="topic">[Hidden Information]</span>
                                                                </ul>
                                                            ) : (
                                                                <span className="txt"><Skeleton count={1.5} /></span>
                                                            )
                                                        }
        
                                                    </div>
        
                                                    <div>
                                                        <h2 className='title small'>Phone number</h2>
                                                        {
                                                            !data?.is_removed ? (
                                                                <ul className='listTopic'>
                                                                    <span style={{ color: "#64b9da" }} className="topic">[Hidden Information]</span>
                                                                </ul>
                                                            ) : (
                                                                <span className="txt"><Skeleton count={1.5} /></span>
                                                            )
                                                        }
        
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div className='pagginationCategory'>
                                                <div className='pagination'>
        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            )}
        </>
    );
}

export default PreviewBuyer;