
"use client";


import { useKeycloak } from "@react-keycloak/web";
import { NavLink, useNavigate } from "react-router-dom";
import avatar from "../resources/images/avatar.png";
import logoLettre from "../resources/images/logo-lettre.svg";
import "./MainLayout.scss";
import { useEffect, useState } from "react";
import { useGetNumberViewMessage } from "../api/user";

interface HeaderProps {
    isEditMode: boolean;
}

const Header: React.FC<HeaderProps> = ({ isEditMode }) => {
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();

    const [enabledViewNumberMessage, setEnabledViewNumberMessage] = useState(true);
    const { data: numberMessage, isFetched: isFetchNumberMessage } = useGetNumberViewMessage(enabledViewNumberMessage);


    useEffect(() => {
        if (isFetchNumberMessage) setEnabledViewNumberMessage(false);
    }, [isFetchNumberMessage])
      
    const handleLogOut = () => {
        if (initialized && keycloak?.authenticated) {
            keycloak.logout().then(() => {navigate('/');});
        }
    };

    const handleGotoDI = () => {
        window.open(process.env.REACT_APP_DI_URL, '_blank');
    };

    const handleSignin = () => {
        keycloak.login();
    }

    const goToMessaging = () => {
        const messagingUrl = `${process.env.REACT_APP_MESSAGING_URL}`;
        window.open(messagingUrl, '_blank')
      }

    return (
        <div id="principalMenu" className={`${ isEditMode ? 'listingEditMode' : ''}` }>
            <div className="dynamicMenu">
                <div className="principalMenu">
                <div className="container mx-auto">
                    <div className="leftItemHeader">
                    <div className="logoHeader">
                        <img src={logoLettre} alt="" />
                    </div>
                    <div className="menuDynamic tl">
                        <div className="item">
                        <NavLink to="/">M&A matchmaking</NavLink>
                        </div>
                    </div>
                    </div>
                    <div className="rightItemHeader">
                    { initialized &&
                        <div className="menuDynamic tr">
                            <div className="item">
                            {keycloak?.authenticated &&
                                (
                                    <>
                                        <a href="javascript:;" className="parent linkHeader" onClick={goToMessaging}>
                                            <span className="icon-message"></span>
                                            <span className="mobile">Messages</span>
                                            {
                                                numberMessage && numberMessage.data && (numberMessage.data.seller + numberMessage.data.buyer) > 0 && (
                                                    <span className="non-lu">{numberMessage.data.seller + numberMessage.data.buyer}</span>
                                                )
                                            }
                                        </a>
                                    </>
                                )
                            }
                            </div>
                            <div className="item">
                                {keycloak?.authenticated &&
                                    (
                                        <a href="javascript:;" className="linkHeader">
                                            <span className="icon-notification"></span>
                                            <span className="mobile">Notification</span>
                                        </a>
                                    )
                                }
                            </div>
                            <div className="item">
                                {keycloak?.authenticated &&
                                    <>
                                        <a href="javascript:;" className="parent linkHeader">
                                            <div className={`profilImg`}>
                                            <img src={avatar} alt="" />
                                            </div>
                                            <span className="mobile">My account</span>
                                        </a>
                                        <div className="submenu">
                                            <a href="javascript:;" onClick={() => handleGotoDI()}>Go to Devinsider</a>
                                            <a href="javascript:;" onClick={() => handleLogOut()}>Sign out</a>
                                        </div>
                                    </>
                                }
                                
                                {!keycloak?.authenticated &&
                                    <>
                                    {/* TODO Jacky */}
                                    <div className="logins">
                                        <a className="link" href="javascript:;" onClick={() => handleSignin()}>Sign in</a>
                                        <a className="link" href="javascript:;">Get started</a>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
