import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UseGetMySellerListing } from "../../api/seller";
import { useGetTypeUser } from "../../api/user";
import { useKeycloakHooks } from "../../hooks/keycloak";

interface IProps {
    showSearch: boolean;
    handleGoToFavorite?: () => void;
    searchInput?: string;
    setSearchInput?: (value: string) => void;
    handleSearch?: () => void;
}

const urlType = {
    favorites: "/my-favorite",
    listing: "/my-listing",
    detail: "/details",
    matchmaking: "/my-matching",
    detail_matchmaking: "/detail/matching"
};

function SellerSecondMenu(props: IProps) {
    const navigate = useNavigate();
    let { id } = useParams();

    const [showFavoriteBtn, setShowFavoriteBtn] = useState(true);
    const [showMyListingBtn, setShowMyListingBtn] = useState(true);
    const [showMatchmakingBtn, setShowMatchmakingBtn] = useState(false);
    const [enabled, setEnabled] = useState(false);

    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();
    const { data: userRoles } = useGetTypeUser(tokenInfo, enabled);

    const { data: mySellerListing } = UseGetMySellerListing(enabled);

    useEffect(() => {
        if(tokenInfo?.user_uuid) setEnabled(true);
        if(userRoles && mySellerListing && mySellerListing.seller) setEnabled(false);
    }, [tokenInfo?.user_uuid, userRoles, mySellerListing])

    useEffect(() => {
        if (initialized && keycloak.authenticated && userRoles) {
            if (!userRoles?.isSeller) {
                setShowFavoriteBtn(false);
                setShowMyListingBtn(false);
            } else {
                setShowFavoriteBtn(true);
                setShowMyListingBtn(true);
            }
        } else {
            setShowFavoriteBtn(false);
            setShowMyListingBtn(false);
        }
    }, [userRoles, mySellerListing]);

    useEffect(() => {
        if (initialized && keycloak.authenticated && userRoles) {
            if (userRoles?.isSeller && mySellerListing) {
                if (mySellerListing.seller && mySellerListing.seller.is_published && !mySellerListing.seller.is_removed){
                    setShowMatchmakingBtn(true);
                } else setShowMatchmakingBtn(false);
            }
            
        }
    }, [initialized, keycloak.authenticated, userRoles, mySellerListing]);


    const handleGoToMyListing = () => {
        if(keycloak?.authenticated){
            if(userRoles && userRoles.isSeller && mySellerListing?.seller) {
                navigate("/seller/listing/my-listing")
            } else return;
        }
        else keycloak?.login();
    }

    const handleGoToMatching = () => {
        if(keycloak?.authenticated){
            if(userRoles && userRoles.isSeller && mySellerListing?.seller.is_published) {
                navigate("/seller/listing/my-matching")
            } else return;
        }
        else keycloak?.login();
    }

    const handleGoToMyFavoriteMatching = () => {
        if (keycloak?.authenticated) {
            if(userRoles && userRoles.isSeller && mySellerListing?.seller.is_published) {
                navigate("/seller/listing/my-favorite")
            } else return;
        }
        else keycloak?.login();
    }

    const path = window.location.href;

    const renderHeaderActive = (type: string) => {
        if(type == "detail") return path?.includes(type) && id == mySellerListing?.seller?.id ? "active" : "";
        return path?.includes(type) ? "active" : "";
    }

    return (
        <div className="dynamicMenu secondFix">
            <div className="secondMenu">
                <div className="container mx-auto">
                    <div className="leftItemHeader justify-content-start">
                        <h2 className="title">
                            <a className="colorInherit" href="javascript:;">
                                M&A matchmaking
                            </a>
                        </h2>
                        <div className="menuDynamic bl">
                            {showFavoriteBtn &&
                                <div className="item">
                                    <a
                                    href="javascript:;" 
                                    className={renderHeaderActive(urlType?.favorites)}
                                    onClick={() =>handleGoToMyFavoriteMatching()}>
                                    Favorites
                                    </a>
                                </div>
                            }
                            {showMyListingBtn &&
                                <div className="item" onClick={() => handleGoToMyListing()}>
                                    <a
                                        href="javascript:;"
                                        className={renderHeaderActive(urlType?.listing) || renderHeaderActive(urlType?.detail)}
                                    >
                                    My listing
                                    </a>
                                </div>
                            }
                            {showMatchmakingBtn &&
                                <div className="item" onClick={() => handleGoToMatching()}>
                                    <a
                                    href="javascript:;"
                                    className={renderHeaderActive(urlType?.matchmaking) || renderHeaderActive(urlType?.detail_matchmaking)}
                                    >
                                        
                                    Matchmaking
                                    </a>
                                </div>
                            }
                        </div>
                        {props?.showSearch &&
                            <div className="searchWord classified">
                                <input
                                    type="text"
                                    className="keyword"
                                    value={props?.searchInput}
                                    onChange={(e) => props?.setSearchInput && props?.setSearchInput(e.target.value)}
                                />
                                <button onClick={props?.handleSearch}>
                                    <span className="icon-search"></span>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SellerSecondMenu