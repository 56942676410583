import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMyBuyerListing } from "../../api/buyer";
import { UseGetMySellerListing } from "../../api/seller";
import { useGetTypeUser } from "../../api/user";
import { useKeycloakHooks } from "../../hooks/keycloak";
import Buyer from '../../resources/images/buyer.png';
import Plan from '../../resources/images/plans.png';
import createListing from '../../resources/images/createListing.png';
import "../../resources/scss/main.scss";
import { LoaderCommon } from "../../widget/loader";
import AsideListe from "./AsideListe";
import './dashboard.scss';

export default function Dashboard() {
  const { tokenInfo, initialized } = useKeycloakHooks();
  const [enabled, setEnabled] = useState(false);

  const { data: userRoles, isLoading: userRolesLoading } = useGetTypeUser(tokenInfo, enabled);
  const { data: mySellerListing, isFetched: isSellerFetched, isLoading: isSellerLoading } = UseGetMySellerListing(enabled);
  const { data: myBuyerListing, isFetched: isBuyerFetched, isLoading: isBuyerLoading } = useGetMyBuyerListing(enabled);

  useEffect(() => {
    if (tokenInfo?.user_uuid) setEnabled(true);
    if (userRoles && mySellerListing && myBuyerListing) setEnabled(false);
  }, [tokenInfo?.user_uuid, userRoles, mySellerListing, myBuyerListing])

  const navigate = useNavigate();

  const goToBuyerCreateListing = () => {
    navigate("/buyer/listing/creation");
  }

  const goToSellerCreateListing = () => {
    navigate("/seller/listing/creation");
  }

  const goToSettings = () => {
    navigate("/settings");
  }

  const goToManageSellerListing = () => {
    mySellerListing && navigate(`/seller/listing/details/${mySellerListing?.seller?.id}`);
  }

  const goToManageBuyerListing = () => {
    myBuyerListing && navigate(`/buyer/listing/details/${myBuyerListing?.id}`);
  }

  const handleGoToMyMatchingBuyer = () => {
    myBuyerListing && navigate(`/buyer/listing/my-matching`);
  }

  const handleGoToMyMatchingSeller = () => {
    mySellerListing && navigate(`/seller/listing/my-matching`);
  }

  return (
    <>
      {(userRolesLoading || !initialized || isSellerLoading || isBuyerLoading) &&
        <div className='loaderCommon'>
          <LoaderCommon />
        </div>
      }
      {(!userRolesLoading && initialized && isSellerFetched && isBuyerFetched) && (
        <div>
          <AsideListe />
          <div className='container mx-auto db'>
            {userRoles &&
              <ul className='listsoftwarevendor'>
                <li>
                  <h2 className='title'>Seller listing</h2>
                  <div className="HPimage">
                    <img
                      src={createListing}
                      alt=""
                    />
                  </div>
                  {userRoles && !userRoles.isSeller &&
                    <div onClick={() => goToSellerCreateListing()} className='learnMore'>
                      Create listing
                    </div>
                  }
                  {userRoles && userRoles.isSeller &&
                    <>
                      <div className='learnMore' onClick={() => goToManageSellerListing()}>
                        Manage listing
                      </div>
                      {mySellerListing && mySellerListing.seller.is_published &&
                        <div className='learnMore mt-0' onClick={() => handleGoToMyMatchingSeller()}>
                          Match with buyers
                        </div>
                      }
                    </>
                  }
                </li>
                <li>
                  <h2 className='title'>Buyer listing</h2>
                  <div className="HPimage">
                    <img
                      src={Buyer}
                      alt=""
                    />
                  </div>
                  {userRoles && !userRoles.isBuyer &&
                    <div onClick={() => goToBuyerCreateListing()} className='learnMore'>
                      Create listing
                    </div>
                  }
                  {userRoles && userRoles.isBuyer &&
                    <>
                      <div className='learnMore' onClick={() => goToManageBuyerListing()}>
                        Manage listing
                      </div>
                      {myBuyerListing && myBuyerListing?.is_published && !myBuyerListing?.is_removed &&
                        <div className='learnMore mt-0' onClick={() => handleGoToMyMatchingBuyer()}>
                          Match with sellers
                        </div>
                      }
                    </>
                  }
                </li>
                <li>
                  <h2 className='title'>Plans & billing</h2>
                  <div className="HPimage">
                    <img
                      src={Plan}
                      alt=""
                    />
                  </div>
                  <div className='learnMore' onClick={() => goToSettings()}>
                    Manage plans & billing
                  </div>
                </li>
              </ul>
            }
          </div>
        </div>
      )}
    </>
  );
}
