import ISO3166 from 'iso-3166-1-alpha-2';
import { Base64 } from 'js-base64';
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useGetBuyer, useGetFiltersBuyer } from '../../../api/buyer';
import { useGetTypeUser } from '../../../api/user';
import SeeBtn from '../../../components/seeBtn';
import { useKeycloakHooks } from '../../../hooks/keycloak';
import BuyerSecondMenu from '../../../layouts/second-headers/buyer';
import { addToArray, paginate } from '../../../utils/outils';
import { LoaderCommon } from '../../../widget/loader';
import '../buyer.scss';
import { urlsApi } from "../../../api/urls/apiUrl";

export default function BuyerListing() {
    const navigate = useNavigate();
    const [limitSolution, setLimitSolution] = useState(6);
    const [limitIndustry, setLimitIndustry] = useState(6);
    const [limitLocation, setLimitLocation] = useState(6);
    const [selectedSolution, setSelectedSolution] = useState<Array<string>>([]);
    const [selectedIndustry, setSelectedIndustry] = useState<Array<string>>([]);
    const [selectedLocation, setSelectedLocation] = useState<Array<string>>([]);
    const [searchInput, setSearchInput] = useState<string>('');

    const [params, setParams] = useState({
        location: [] as string[],
        horizontal: [] as string[],
        vertical: [] as string[],
    });

    const [enabled, setEnabled] = useState(false);

    const { data: filtersData } = useGetFiltersBuyer(enabled);
    const [filteredDatas, setFilteredDatas] = useState<Array<any>>([]);
    const { keycloak, tokenInfo, initialized } = useKeycloakHooks();

    const [isLocationOpen, setIsLocationOpen] = useState(true);
    const [isSolutionOpen, setIsSolutionOpen] = useState(true);
    const [isIndustryOpen, setIsIndustryOpen] = useState(true);

    const toggleFilterSection = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
        setState(prevState => !prevState);
    };

    const buyerData = useGetBuyer(enabled);
    const { data } = keycloak?.token ? buyerData : { data: [] };

    const { data: userRoles, isLoading: userRolesLoading } = useGetTypeUser(tokenInfo, enabled);
    const [hasListing, setHasListing] = useState(false);

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            if (userRoles && (userRoles?.isSeller || userRoles?.isBuyer)) {
                setHasListing(true);
            }
        }
    }, [userRoles]);

    useEffect(() => {
        if (keycloak?.token && params) setEnabled(true);
        if (data && filtersData) setEnabled(false);
    }, [keycloak?.token, params, data, filtersData])

    const handleSeeSolution = (action: string) => {
        setLimitSolution(action === '0' ? filtersData?.software_solution.length : 6);
    };

    const handleSeeIndustry = (action: string) => {
        setLimitIndustry(action === '0' ? filtersData?.targeted_industry.length : 6);
    };

    const handleSeeLocation = (action: string) => {
        setLimitLocation(action === '0' ? filtersData?.location.length : 6);
    }

    const handleSelectSolution = (element: string) => {
        setSelectedSolution(addToArray(selectedSolution, element));
    }

    const handleSelectIndustry = (element: string) => {
        setSelectedIndustry(addToArray(selectedIndustry, element));
    }

    const handleSelectLocation = (element: any) => {
        setSelectedLocation(addToArray(selectedLocation, element));
    }
    const handleSearch = () => {
        if (searchInput.trim() !== '') {
            const filteredData = data?.filter((el: any) => {
                const country = ISO3166.getCountry(el?.location);
                const locationMatches = country && country.toLowerCase().includes(searchInput.toLowerCase());
                const goalMatches = el?.main_acquisition_goal.toLowerCase().includes(searchInput.toLowerCase());
                const hqLocationMatches =
                    el?.preferred_hq_location &&
                    el?.preferred_hq_location.some((hqLocation: any) =>
                        ISO3166.getCountry(hqLocation.iso2)?.toLowerCase().includes(searchInput.toLowerCase())
                    );


                const companyReasonMatches = el?.company_reason?.toLowerCase().includes(searchInput.toLowerCase());
                const stageMatches = el?.stage?.toLowerCase().includes(searchInput.toLowerCase());

                return (
                    locationMatches ||
                    goalMatches ||
                    hqLocationMatches ||
                    companyReasonMatches ||
                    stageMatches
                );
            });

            return filteredData && filteredData.length > 0 ? filteredData : [];
        }

        return [];
    };

    const [imageSrc, setImageSrc] = useState<string>('');
    const base64String = data?.company_image;

    useEffect(() => {
        if (base64String) {
            const imageSrcs = Base64.decode(base64String);
            setImageSrc(imageSrcs);
        }
    }, [base64String]);

    const filterBuyerListing = () => {
        const param = {
            location: selectedLocation,
            horizontal: selectedSolution,
            vertical: selectedIndustry,
        };

        const filteredData = data?.filter((el: any) => {
            const locationMatch = (!param.location || (Array.isArray(el?.location) && el.location.some((loc: any) => param.location.includes(loc))));
            const horizontalMatch = (!param.horizontal || (Array.isArray(el?.software_solution) && el.software_solution.some((sol: any) => param.horizontal.includes(sol))));
            const verticalMatch = (!param.vertical || (Array.isArray(el?.targeted_industry) && el.targeted_industry.some((ind: any) => param.vertical.includes(ind))));

            if (param.location && !param.horizontal && !param.vertical) {
                return locationMatch;
            } else if (!param.location && param.horizontal && !param.vertical) {
                return horizontalMatch;
            } else if (!param.location && !param.horizontal && param.vertical) {
                return verticalMatch;
            } else if (param.location && !param.horizontal && param.vertical) {
                return locationMatch || verticalMatch;
            } else if (!param.location && param.horizontal && param.vertical) {
                return horizontalMatch || verticalMatch;
            } else if (param.location && param.horizontal && !param.vertical) {
                return verticalMatch || horizontalMatch;
            } else {
                return locationMatch || horizontalMatch || verticalMatch;
            }
        });
        const finalFilteredData = filteredData.length > 0 ? filteredData : [];
        setFilteredDatas(finalFilteredData);

        setParams((prevParams) => ({
            ...prevParams,
            ...(param.location ? { location: param.location } : {}),
            ...(param.horizontal ? { horizontal: param.horizontal } : {}),
            ...(param.vertical ? { vertical: param.vertical } : {}),
        }));
    };

    const isOpportunistic = (el: any) => {
        var isOppo = false;

        if (el?.challenge === "I am opportunistic with no specific target") {
            isOppo = true
        }

        return isOppo;
    }



    const hasFilter = filtersData && filtersData.location && filtersData.location.length > 0 && filtersData.software_solution && filtersData.software_solution.length > 0 && filtersData.targeted_industry && filtersData.targeted_industry.length > 0 && userRoles;

    let shouldDisplayBlock = false;

    if (hasFilter) {
        shouldDisplayBlock = true;
    }

    const resetSellerListing = () => {
        let param = {
            location: [],
            horizontal: [],
            vertical: [],
        }
        setSearchInput('');
        setSelectedSolution([]);
        setSelectedLocation([]);
        setSelectedIndustry([]);

        setParams((prevParams) => ({
            ...prevParams,
            ...param
        }));
    }

    const goToBuyerListing = () => {
        navigate("/buyer/listing");
    };

    const goToSellerListing = () => {
        navigate("/seller/listing");
    };

    const goToLogin = () => {
        keycloak.login();
    };

    const goToDetails = (id: any) => {
        navigate(`/buyer/listing/details/${id}`);
    };

    const goToEnrollHomePage = () => {
        navigate("/enroll");
    };

    const goToEnrollAsSeller = () => {
        navigate("/seller/listing/creation");
    };

    const handleEnroll = () => {
        if (keycloak?.authenticated) {
            if (userRoles) {
                if (!userRoles?.isBuyer) goToEnrollHomePage();
                else goToEnrollAsSeller();
            }
        } else {
            goToLogin();
        }
    }

    const handleGoToFavorite = () => {
        if (keycloak?.authenticated) { }
        else keycloak?.login();
    }

    return (
        <>
            {(userRolesLoading || !initialized || !data) &&
                <div className='loaderCommon'>
                    <LoaderCommon />
                </div>
            }
            {(hasListing) && <Navigate to="/" />}
            {(!userRolesLoading && data && initialized) && (
                <div id='buyer-listing' className="findIsvcontainer programContainer sellerContainer">
                    <BuyerSecondMenu
                        showSearch={true}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        handleSearch={handleSearch}
                        handleGoToFavorite={handleGoToFavorite}
                    />
                    <div className="filterFindIsv leftTop hScroll">
                        <div className="contentFilter">
                            <div className="block sellerBuyer pb-0 border-0">
                                <div className="sellers" onClick={() => goToSellerListing()}>
                                    <a href="javascript:;">
                                        <span className="icon-sellers"></span>Sellers
                                    </a>
                                </div>
                                <div className="buyer active" onClick={() => goToBuyerListing()}>
                                    <a href="javascript:;">
                                        <span className="icon-buyerList"></span>Buyers
                                    </a>
                                </div>
                            </div>
                            {
                                shouldDisplayBlock ? (
                                    <>
                                        <div className="containerBtn block infoProgramContainer border-0">
                                            <button className="btn btn-devinsider cancel" onClick={() => filterBuyerListing()}>Filter</button>
                                            <a className="resetFilter" onClick={() => resetSellerListing()}>
                                                <span className="icon-refresh" />
                                                Reset filter
                                            </a>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isLocationOpen ? 'open' : 'closed'}`}>HQ location</h3>
                                            <div className="listCheck">
                                                {
                                                    paginate(filtersData?.location, limitLocation)?.sort((a, b) => {
                                                        const countryA = ISO3166.getCountry(a) || "";
                                                        const countryB = ISO3166.getCountry(b) || "";
                                                        return countryA.localeCompare(countryB);
                                                    })?.map((el: any, index: any) => (
                                                        <div className="custom-control custom-checkbox orange" key={index}>
                                                            <input
                                                                type="checkbox"
                                                                id={`location${index}`}
                                                                className="custom-control-input"
                                                                onChange={() => handleSelectLocation(el)}
                                                                checked={
                                                                    selectedLocation?.find(
                                                                        (selected: any) => selected === el
                                                                    )
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <label htmlFor={`location${index}`} className="custom-control-label">
                                                                {ISO3166.getCountry(el)}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                                <SeeBtn
                                                    dataSource={filtersData?.location}
                                                    limit={limitLocation}
                                                    seeMoreOrLess={(p: string) => handleSeeLocation(p)}
                                                />
                                            </div>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isSolutionOpen ? 'open' : 'closed'}`}>Solution (Horizontal)</h3>
                                            <div className="listCheck">
                                                {
                                                    paginate(filtersData?.software_solution, limitSolution)?.sort()?.map((el: any, index: any) => (
                                                        <>
                                                            <div className="custom-control custom-checkbox orange" key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`solutions${index}`}
                                                                    className="custom-control-input"
                                                                    onChange={() => handleSelectSolution(el)}
                                                                    checked={
                                                                        selectedSolution?.find(
                                                                            (selected: any) => selected === el
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <label htmlFor={`solutions${index}`} className="custom-control-label">
                                                                    {el}
                                                                </label>
                                                            </div>
                                                        </>
                                                    ))
                                                }
                                                <SeeBtn
                                                    dataSource={filtersData?.software_solution}
                                                    limit={limitSolution}
                                                    seeMoreOrLess={(p: string) => handleSeeSolution(p)}
                                                />
                                            </div>
                                        </div>
                                        <div className="block">
                                            <h3 className={`title withChild ${isIndustryOpen ? 'open' : 'closed'}`}>Industry (Vertical)</h3>
                                            <div className="listCheck">
                                                {
                                                    paginate(filtersData?.targeted_industry, limitIndustry)?.sort()?.map((el: any, index: any) => (
                                                        <>
                                                            <div className="custom-control custom-checkbox orange" key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`industries${index}`}
                                                                    className="custom-control-input"
                                                                    onChange={() => handleSelectIndustry(el)}
                                                                    checked={
                                                                        selectedIndustry?.find(
                                                                            (selected: any) => selected === el
                                                                        )
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <label htmlFor={`industries${index}`} className="custom-control-label">
                                                                    {el}
                                                                </label>
                                                            </div>
                                                        </>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                    </div>

                    <div className="container mx-auto minHeightwin sellerRight">
                        <div className="listOfIsv listOfSeller">
                            <div className="scrollContainer">
                                {(!userRoles || (userRoles && !userRoles?.isSeller)) &&
                                    <div className="aboutSection">
                                        To access buyer listings, enroll as a seller.
                                        <div className="mt-3">
                                            <a
                                                className="btn btn-devinsider"
                                                href="javascript:;"
                                                onClick={() => handleEnroll()}
                                            >
                                                Enroll now!
                                            </a>
                                        </div>
                                    </div>
                                }
                                {
                                    (!userRoles?.isSeller && !userRoles?.isBuyer) ? (<></>) : (!userRoles.isSeller) ? (<></>) : (
                                        <ul className="listSeller buyer">
                                            {searchInput.trim() !== '' ? (
                                                handleSearch().map((el: any, index: any) => (
                                                    (el?.is_removed !== true && el?.is_published !== false) && (
                                                        <li className="list" key={index}>
                                                            <div className="logo">
                                                                <>
                                                                    {
                                                                        data?.company_image !== null && data?.company_image !== undefined && data?.company_image !== "" ? (
                                                                            <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + data.company_image}
                                                                                width={60}
                                                                                height={60}
                                                                                alt="Company Logo" />
                                                                        ) : (
                                                                            <span className='icon-building ico'></span>
                                                                        )
                                                                    }
                                                                </>
                                                            </div>
                                                            <div className="desc">
                                                                <div className="title">
                                                                    {isOpportunistic(el) == false ? (
                                                                        <span>
                                                                            Motivated buyer {el?.company_name != "user_anonyma" ? el?.company_name : 'Company Confidential'}
                                                                        </span>
                                                                    ) : (
                                                                        <span>{el?.company_name != "user_anonyma" ? el?.company_name : 'Company Confidential'}</span>
                                                                    )}
                                                                </div>
                                                                <h2 className="about fs-6 mt-2">
                                                                    {el?.stage} <span>{el?.company_reason}</span>
                                                                </h2>
                                                                {/* <div className="about">{el?.company_reason}</div>
                                                                <h2 className="title">
                                                                    {el?.stage}
                                                                </h2> */}
                                                                <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(el?.id)}>
                                                                    More details
                                                                </a>
                                                                <ul>
                                                                    <li className="item">
                                                                        <div>Location</div>
                                                                        <span className="ico icon-location"></span>
                                                                        <span className="txt">{ISO3166.getCountry(el?.location)}</span>
                                                                    </li>
                                                                    <li className="item">
                                                                        <div>Main Acquisition Goal</div>
                                                                        <span className="ico icon-topValue"></span>
                                                                        <span className="txt">{el?.main_acquisition_goal}</span>
                                                                    </li>
                                                                    <li className="item w-100">
                                                                        <div className="item listing">
                                                                            <span style={{ display: "inline-block", marginRight: "1rem" }}>Preferred acquisition target location : </span>
                                                                            {el?.preferred_hq_location && el?.preferred_hq_location.slice(0, 3).map((hqLocation: any, i: number) => (
                                                                                <span className="txt" key={i}>{ISO3166.getCountry(hqLocation.iso2)}</span>
                                                                            ))}
                                                                            {el?.preferred_hq_location && el?.preferred_hq_location.length > 3 && (
                                                                                <span className="txt">...</span>
                                                                            )}
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    )))
                                            ) : (
                                                (filteredDatas && filteredDatas.length > 0) ? (
                                                    filteredDatas.map((el: any, index: any) => (
                                                        (el?.is_removed !== true && el?.is_published !== false) && (
                                                            <li className="list" key={index}>
                                                                <div className="logo">
                                                                    <>
                                                                        {
                                                                            data?.company_image !== null && data?.company_image !== undefined && data?.company_image !== "" ? (
                                                                                <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + data.company_image}
                                                                                    width={60}
                                                                                    height={60}
                                                                                    alt="Company Logo" />
                                                                            ) : (
                                                                                <span className='icon-building ico'></span>
                                                                            )
                                                                        }
                                                                    </>
                                                                </div>
                                                                <div className="desc">
                                                                    <div className="title">
                                                                        {isOpportunistic(el) == false ? (
                                                                            <span>
                                                                                Motivated buyer {el?.company_name != "user_anonyma" ? el?.company_name : 'Company Confidential'}
                                                                            </span>
                                                                        ) : (
                                                                            <span>{el?.company_name != "user_anonyma" ? el?.company_name : 'Company Confidential'}</span>
                                                                        )}
                                                                    </div>
                                                                    <h2 className="about fs-6 mt-2">
                                                                        {el?.stage} <span>{el?.company_reason}</span>
                                                                    </h2>
                                                                    <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(el?.id)}>
                                                                        More details
                                                                    </a>
                                                                    <ul>
                                                                        <li className="item">
                                                                            <div>Location</div>
                                                                            <span className="ico icon-location"></span>
                                                                            <span className="txt">{ISO3166.getCountry(el?.location)}</span>
                                                                        </li>
                                                                        <li className="item">
                                                                            <div>Main Acquisition Goal</div>
                                                                            <span className="ico icon-topValue"></span>
                                                                            <span className="txt">{el?.main_acquisition_goal}</span>
                                                                        </li>
                                                                        <li className="item w-100">
                                                                            <div className="item listing">
                                                                                <span style={{ display: "inline-block", marginRight: "1rem" }}>Preferred acquisition target location : </span>
                                                                                {el?.preferred_hq_location && el?.preferred_hq_location.slice(0, 3).map((hqLocation: any, i: number) => (
                                                                                    <span className="txt" key={i}>{ISO3166.getCountry(hqLocation.iso2)}</span>
                                                                                ))}
                                                                                {el?.preferred_hq_location && el?.preferred_hq_location.length > 3 && (
                                                                                    <span className="txt">...</span>
                                                                                )}
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        )))
                                                ) : (
                                                    data && data.length > 0 ? (
                                                        data.map((el: any, index: any) => (
                                                            (data?.is_removed !== true && data?.is_published !== false) && (
                                                                <li className="list" key={index}>
                                                                    <div className="logo">
                                                                        <>
                                                                            {
                                                                                data?.company_image !== null && data?.company_image !== undefined && data?.company_image !== "" ? (
                                                                                    <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + data.company_image}
                                                                                        width={60}
                                                                                        height={60}
                                                                                        alt="Company Logo" />
                                                                                ) : (
                                                                                    <span className='icon-building ico'></span>
                                                                                )
                                                                            }
                                                                        </>
                                                                    </div>
                                                                    <div className="desc">
                                                                        <div className="title">
                                                                            {isOpportunistic(el) == false ? (
                                                                                <span>
                                                                                    Motivated buyer {el?.company_name != "user_anonyma" ? el?.company_name : 'Company Confidential'}
                                                                                </span>
                                                                            ) : (
                                                                                <span>{el?.company_name != "user_anonyma" ? el?.company_name : 'Company Confidential'}</span>
                                                                            )}
                                                                        </div>
                                                                        <h2 className="about fs-6 mt-2">
                                                                            {el?.stage} <span>{el?.company_reason}</span>
                                                                        </h2>
                                                                        <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetails(el?.id)}>
                                                                            More details
                                                                        </a>
                                                                        <ul>
                                                                            <li className="item">
                                                                                <div>Location</div>
                                                                                <span className="ico icon-location"></span>
                                                                                <span className="txt">{ISO3166.getCountry(el?.location)}</span>
                                                                            </li>
                                                                            <li className="item">
                                                                                <div>Main Acquisition Goal</div>
                                                                                <span className="ico icon-topValue"></span>
                                                                                <span className="txt">{el?.main_acquisition_goal}</span>
                                                                            </li>
                                                                            <li className="item w-100">
                                                                                <div className="item listing">
                                                                                    <span style={{ display: "inline-block", marginRight: "1rem" }}>Preferred acquisition target location : </span>
                                                                                    {el?.preferred_hq_location && el?.preferred_hq_location.slice(0, 3).map((hqLocation: any, i: number) => (
                                                                                        <span className="txt" key={i}>{ISO3166.getCountry(hqLocation.iso2)}</span>
                                                                                    ))}
                                                                                    {el?.preferred_hq_location && el?.preferred_hq_location.length > 3 && (
                                                                                        <span className="txt">...</span>
                                                                                    )}
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                            )))
                                                    ) : (
                                                        <li className="no-data-message"></li>
                                                    )
                                                )
                                            )}
                                        </ul>
                                    )
                                }
                            </div>
                            <div className="pagginationCategory">
                                <div className="pagination"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
}
