import { ChangeEvent, useEffect, useState } from "react";
import { Accordion, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CreatableSelect from "react-select/creatable";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useGetStaticParams } from "../../../api/staticParams";
import IndustriesList from "../../../components/industriesList";
import SolutionsList from "../../../components/solutionsList";
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { ITransformedCountry } from "../../../types/countries";
import { Countries } from "../../../widget/countries";
import { useGetMySpecificTehnologyListing } from '../../../api/buyer';
import { renderOptions } from '../utils/renderOptions';

type ITransformedISV = {
    value: number;
    label: string;
}
type IISV = {
    id: number;
    attributes: {
        Label: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
    };
}
type SelectedOptions = {
    lifecycle: string[];
};

interface UserSpecificTechnology {
    id: number;
    idUser: string;
    specificTechnology: any[];
}


type EcosystemItem = {
    value: number | string;
    label: string;
    __isNew__?: boolean
};


export default function AcquisitionTarget(props: any) {
    const getInitialState = () => {
        return {
            ...props?.data,
            preferred_hq_location: props?.data?.preferred_hq_location?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.preferred_hq_location : [],
            lifecycle_stage: props?.data?.lifecycle_stage?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.lifecycle_stage : [],
            preferred_industries: props?.data?.preferred_industries?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.preferred_industries : [],
            preferred_software: props?.data?.preferred_software?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.preferred_software : [],
            preferred_featured_software: props?.data?.preferred_featured_software?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.preferred_featured_software : [],
            ecosystem: props?.data?.ecosystem?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.ecosystem : [],
            opportunity: props?.data?.opportunity !== 'manda_deleted_manda' ? props?.data?.opportunity : '',
        };
    }

    const [formState, setFormState] = useState(getInitialState());
    useEffect(() => {
        if (props?.isOpen) {
            setFormState(getInitialState());
        }
    }, [props?.isOpen]);

    const [isUpdating, setIsUpdating] = useState<boolean>(props?.isUpdating || false);
    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const [selectedOption, setSelectedOption] = useState({
        opportunity: formState?.opportunity || '',
    });

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, property: string) => {
        const value = event.target.value;
        setSelectedOption((prevState) => ({ ...prevState, [property]: value }));
    };
    const [enableGetOpportunities, setEnableGetOpportunities] = useState(true);
    const { data: opportunitiesData, isFetched: isOpportunitiesFetched } = useGetStaticParams('OPPORTUNITY_INTERESTS', enableGetOpportunities);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isDisplayStage, setIsDisplaStage] = useState(false);
    const [isDisplayIndustry, setIsDisplayIndustry] = useState(false);
    const [isDisplaySolution, setIsDisplaySolution] = useState(false);
    const [isDisplayISV, setIsDisplayISV] = useState(false);
    const [isDisplayTechnology, setIsTechnology] = useState(false);
    const [isDisplayOpportunity, setIsDisplayOpportunity] = useState(false);

    useEffect(() => {
        if (isOpportunitiesFetched) setEnableGetOpportunities(false);
    }, [isOpportunitiesFetched]);

    useEffect(() => {
        setSelectedOption({
            opportunity: formState?.opportunity || '',
        });
    }, [formState]);

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
        switch (eventKey) {
            case '0':
                setIsAccordionOpen(true);
                setIsDisplaStage(!isDisplayStage);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayISV(false);
                setIsTechnology(false);
                setIsDisplayOpportunity(false);
                break;
            case '1':
                setIsAccordionOpen(true);
                setIsDisplaStage(false);
                setIsDisplayIndustry(!isDisplayIndustry);
                setIsDisplaySolution(false);
                setIsDisplayISV(false);
                setIsTechnology(false);
                setIsDisplayOpportunity(false);
                break;
            case '2':
                setIsAccordionOpen(true);
                setIsDisplaStage(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(!isDisplaySolution);
                setIsDisplayISV(false);
                setIsTechnology(false);
                setIsDisplayOpportunity(false);
                break;
            case '3':
                setIsAccordionOpen(true);
                setIsDisplaStage(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayISV(!isDisplayISV);
                setIsTechnology(false);
                setIsDisplayOpportunity(false);
                break;
            case '4':
                setIsAccordionOpen(true);
                setIsDisplaStage(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayISV(false);
                setIsDisplayOpportunity(!isDisplayOpportunity);
                setIsTechnology(false);

                break;
            case '5':
                setIsAccordionOpen(true);
                setIsDisplaStage(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayISV(false);
                setIsDisplayOpportunity(false);
                setIsTechnology(!isDisplayTechnology);
                break;
        }
    };

    const [lifecycleError, setLifecycleError] = useState('');
    const [industryError, setIndustryError] = useState('');
    const [solutionError, setSolutionError] = useState('');
    const [geographicSalesError, setGeographicSalesError] = useState('');
    const [ecosystemError, setEcosystemError] = useState('');

    const validateForm = () => {
        let hasError = false;
        if (selectedIsv.length === 0) {
            setLifecycleError(' ERROR This field is required');
            hasError = true;
        }

        if (!formState?.preferred_industries || formState?.preferred_industries.length < 1) {
            setIndustryError(' ERROR This field is required');
            hasError = true;
        }

        if ((!formState?.preferred_software && formState?.preferred_featured_software) || [...formState?.preferred_software, ...formState?.preferred_featured_software].length < 1) {
            setSolutionError(' ERROR This field is required');
            hasError = true;
        }
        if (currentSelectedList.length === 0 || currentSelectedList === undefined) {
            setEcosystemError(' ERROR This field is required');
            hasError = true;
        }
        if (formState?.preferred_hq_location.length == 0) {
            setGeographicSalesError(' ERROR This field is required');
            hasError = true;
        }

        if (!selectedOption.opportunity || selectedOption.opportunity === '' || selectedOption.opportunity === 'Select an option...') {
            setOpportunityError(' ERROR This field is required');
            hasError = true;
        }

        return hasError;
    }

    const handleCloseModal = () => {
        setIsDisplaStage(false);
        setIsDisplayIndustry(false);
        setIsDisplaySolution(false);
        setIsDisplayISV(false);
        setFormState({ ...props?.data });
        setIsAccordionOpen(false);
        props?.closeModal();
    };

    const handleSave = async () => {
        setIsUpdating(!props?.isUpdating);
        const stepHasErrors = validateForm();
        if (stepHasErrors) {
            setIsUpdating(false);
            return
        }
        await props?.confirmSave(formState);
        setIsUpdating(false);
        setIsDisplaStage(false);
        setIsDisplayIndustry(false);
        setIsDisplaySolution(false);
        setIsDisplayISV(false);
        setIsAccordionOpen(false);
    };

    const transformISV = (countries: IISV[]): ITransformedISV[] => {
        return countries.map((ISV: IISV) => ({
            value: ISV.id,
            label: ISV.attributes.Label,
        }));
    };

    const transformCountryProps = (stages: string): ITransformedCountry[] => {
        if (typeof stages !== 'string' || stages.trim() === '') {
            return [];
        }
        const stagesArray = stages.split(',');
        let counter = 1;
        return stagesArray.map((item) => ({
            value: counter++,
            label: item.trim(),
            continent: '',
            iso2: '',
            iso3: ''
        }));
    };

    const animatedComponents = makeAnimated();
    const [opportunityError, setOpportunityError] = useState('');

    const preferredhqlocationAsString = JSON.stringify(props.data.preferred_hq_location).replace(/(\["|"\])/g, '');

    const transformToObjects = (stages: string[], valueType: string): { [key: number]: IISV } => {
        const transformedObject: { [key: number]: IISV } = {};

        stages.forEach((stage, index) => {
            transformedObject[index + 1] = {
                id: index + 1,
                attributes: {
                    Label: stage,
                    createdAt: "",
                    updatedAt: "",
                    publishedAt: ""
                }
            };
        });

        return transformedObject;
    };

    const newLifecycle = transformToObjects(formState?.lifecycle_stage, "number");

    const transformedIsvArray = Object.values(newLifecycle).map((item: IISV) => ({
        value: item.id,
        label: item.attributes.Label,
    }));

    const [selectedIsv, setSelectedIsv] = useState<ITransformedISV[]>([]);

    const [transformedData, setTransformedData] = useState<ITransformedISV[]>(transformedIsvArray || []);

    useEffect(() => {
        const newLifecycle = transformToObjects(formState?.lifecycle_stage, "number");

        const transformedIsvToArray = Object.values(newLifecycle).map((item: IISV) => ({
            value: item.id,
            label: item.attributes.Label,
        }));

        const transformedIsvArrays = transformedIsvToArray.map((item: any) => ({
            value: item.value,
            label: item.label,
        }));
        setSelectedIsv(transformedIsvArrays);
    }, [formState?.lifecycle_stage]);

    const [selectedCompanyHeadquarters, setSelectedCompanyHeadquarters] = useState<ITransformedCountry[]>(() => {
        if (props.data.preferred_hq_location) {
            return transformCountryProps(preferredhqlocationAsString);
        } else {
            return [];
        }
    });

    const [activeId, setActiveId] = useState<any>("null");

    const [enableGetCompanyHQ, setEnableGetCompanyHQ] = useState(true);
    const [enableGetLifecycles, setEnableGetLifecycles] = useState(true);

    const { data: companyHeadQuarter, isFetched: isCompanyHQFetched } = useGetStaticParams('COUNTRIES', enableGetCompanyHQ);
    const { data: ISV, isFetched: isLifecyclesFetched } = useGetStaticParams('LIFECYCLES', enableGetLifecycles);

    useEffect(() => {
        if (isLifecyclesFetched) {
            setEnableGetLifecycles(false);
            setTransformedData(transformISV(ISV?.data));
        }
        updateState('lifecycle_stage', selectedIsv);
    }, [isLifecyclesFetched]);

    useEffect(() => {
        if (isCompanyHQFetched) {
            setEnableGetCompanyHQ(false);
        }
        updateState('companyHeadCarter', selectedCompanyHeadquarters);
    }, [isCompanyHQFetched]);

    useEffect(() => {

        if (formState?.preferred_industries.length > 0) {
            setIndustryError('');

        }
        if (formState?.preferred_software.length > 0 || formState?.preferred_featured_software.length > 0) {
            setSolutionError('');
        }

        if (formState?.preferred_hq_location.length > 0) {
            setGeographicSalesError('');
        }
        if (selectedIsv.length > 0) {
            setLifecycleError('');
        }
        if (currentSelectedList !== undefined && currentSelectedList.length > 0) {
            setEcosystemError('');
        }
        if (selectedOption.opportunity !== undefined && selectedOption.opportunity !== 'Select an option...' && selectedOption.opportunity !== '') {
            setOpportunityError('');
        }

    }, [formState?.preferred_industries, formState?.preferred_software, selectedIsv]);

    const addTargetedIndustry = (element: any) => {
        updateState('preferred_industries', [...formState?.preferred_industries, element?.attributes?.Label]);
    }

    const removeTargetedIndustry = (element: any) => {
        updateState('preferred_industries', formState?.preferred_industries?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));
    }

    const addSoftwareSolution = (element: any) => {
        updateState('preferred_software', [...formState?.preferred_software, element?.attributes?.Label]);
    }

    const addFeaturedSolution = (element: any) => {
        updateState('preferred_featured_software', [...formState?.preferred_featured_software, element?.attributes?.Label]);
    }

    const removeSolution = (element: any) => {
        updateState('preferred_software', formState?.preferred_software?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));

        updateState('preferred_featured_software', formState?.preferred_featured_software?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));
    }

    const removeItemSelected = (element: any) => {
        updateState('preferred_software', formState?.preferred_software?.filter(
            (el: any) => el !== element
        ));

        updateState('preferred_featured_software', formState?.preferred_featured_software?.filter(
            (el: any) => el !== element
        ));
    }

    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    };

    const filteredOptions = ISV?.data?.map((el: any) => {
        return {
            label: el?.attributes?.Label,
            value: el?.attributes?.Label
        };
    }).filter((option: { label: string; }) => !selectedIsv.some((selectedOption: { label: string; }) => selectedOption.label === option.label));


    const geographicData = companyHeadQuarter && companyHeadQuarter
        ? companyHeadQuarter.data.reduce((acc: any, current: any) => {
            if (current && current.attributes) {
                const continent = current.attributes.Continent;
                const iso2 = current.attributes.ISO2;
                const name = current.attributes.Name;

                if (continent && iso2 && name) {
                    if (!acc[continent]) {
                        acc[continent] = {
                            Continent: continent,
                            Countries: [{ ISO2: iso2, Name: name }],
                        };
                    } else {
                        acc[continent].Countries.push({ ISO2: iso2, Name: name });
                    }
                }
            }
            return acc;
        }, {})
        : {};


    const [currentSelected, setCurrentSelected] = useState<{
        value: number;
        label: string;
    }>();


    const [userSpecificTechnologies, setUserSpecificTechnologies] = useState<Array<UserSpecificTechnology>>([]);
    const [technologiesPartnership, setTechnologiesPartnership] = useState<Array<{ value: number; label: string }>>([]);




    const [currentSelectedList, setCurrentSelectedList] = useState<Array<{ value: number; label: string }>>([]);

    function arraysEqual(arr1: any[], arr2: any[]) {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }

    useEffect(() => {
        const ecosystemArray = Array.isArray(formState?.ecosystem) && formState.ecosystem.length > 0 ?
            formState.ecosystem.map((item: any, index: number) => (
                typeof item === 'object' ? item : { value: index, label: item }
            )) : [];
        const newSelectedList = ecosystemArray.map((item: EcosystemItem, index: number) => ({
            value: index,
            label: item.label
        }));
        if (!arraysEqual(newSelectedList, currentSelectedList)) {
            if (formState?.ecosystem?.find((el: any) => el !== "manda_deleted_manda")) {
                setCurrentSelectedList(newSelectedList)
            }
            else {
                setCurrentSelectedList([]);
            }
        }
    }, [formState?.ecosystem]);


    const addListOption = (data: any) => {
        if (currentSelected) {
            let currentOptionList = technologiesPartnership.filter(
                (el: { value: number; label: string }) => el.value !== data.value
            );

            setCurrentSelectedList([...currentSelectedList, currentSelected]);
            setTechnologiesPartnership(currentOptionList);
            setCurrentSelected({ label: '', value: 0 });
            updateState('ecosystem', [...currentSelectedList, currentSelected]);
        }
    };

    const removeSelected = (id: number) => {
        let data = currentSelectedList?.find(
            (el: { value: number; label: string }) => el.value === id
        );
        let tempValue = currentSelectedList?.filter(
            (el: { value: number; label: string }) => el.value !== id
        );
        setCurrentSelectedList(tempValue);
        if (data) {
            setTechnologiesPartnership([data, ...technologiesPartnership]);
        }
        updateState('ecosystem', tempValue);
    };



    const [enableEcosystems, setEnableEcosystems] = useState(true);
    const { data: ecosystemsData, isFetched: isEcosystemsFetched } = useGetStaticParams('TECHNO_ECOSYSTEM', enableEcosystems);
    const [enableSpecificTechnologie, setEnableSpecificTechnologie] = useState(true);
    const { data: userSpecificTechnologie, isFetched: isSpecificTechnologieFetched } = useGetMySpecificTehnologyListing(enableSpecificTechnologie);


    useEffect(() => {
        if (isEcosystemsFetched) setEnableEcosystems(false);
    }, [isEcosystemsFetched]);

    useEffect(() => {
        if (ecosystemsData && ecosystemsData.data) {
            let combinedData = [];

            const mappedData = ecosystemsData.data.map((item: any) => ({
                value: item.id,
                label: item.attributes.Label
            }));

            if (formState?.ecosystem) {
                combinedData = mappedData.filter((item: { label: any; }) => !formState?.ecosystem.includes(item.label));
            } else {
                combinedData = [...mappedData];
            }

            if (userSpecificTechnologies.length > 0) {
                const lastEcosystemDataId = ecosystemsData.data[ecosystemsData.data.length - 1].id;
                const transformedUserSpecificTechnologies = userSpecificTechnologies.map((item, index) => ({
                    value: lastEcosystemDataId + index + 1,
                    label: item
                }));
                combinedData = [...combinedData, ...transformedUserSpecificTechnologies];
            }

            setTechnologiesPartnership(combinedData);
        }
    }, [ecosystemsData, formState?.ecosystem, userSpecificTechnologies]);

    useEffect(() => {
        if (userSpecificTechnologie) {
            let specificTech: Array<UserSpecificTechnology> = [];
            userSpecificTechnologie.forEach((item: UserSpecificTechnology) => {
                specificTech = specificTech.concat(item.specificTechnology);
            });
            setUserSpecificTechnologies(specificTech);
        }
    }, [userSpecificTechnologie]);
    const isHaveSelectedLabel =
        currentSelected && currentSelected.label;

    return (
        <Modal id="accord" show={props?.isOpen} className='createCompanypopup large forStep5custom buyer-modal'>
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{ fontSize: "1rem", color: "#656565" }} className='title'>{props?.title}</h2>

                    <button onClick={() => handleCloseModal()}
                        className="close">
                        <span className="icon-close-pop" aria-hidden="true"></span>

                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className=''>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => handleAccordionOpen('0')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Select what stages  of the ISV life cycle you are the most intersted in</label>{" "}<span style={{ display: "inline-block", marginLeft: "1rem" }}>{formState?.lifecycle_stage.length > 0 ? formState?.lifecycle_stage.length + ' stage(s) selected' : null}</span>
                                {
                                    lifecycleError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {lifecycleError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required mt-3 p-0'>
                                <Select
                                    classNamePrefix='lifeCycle'
                                    closeMenuOnSelect={false}
                                    placeholder="Select all that apply ..."
                                    isMulti
                                    value={selectedIsv}
                                    options={filteredOptions}
                                    onChange={(selectedOptions) => {
                                        setSelectedIsv(selectedOptions as ITransformedISV[]);
                                        const lifecycleStages = selectedOptions.map(option => option.label);
                                        updateState('lifecycle_stage', lifecycleStages);
                                    }}
                                />

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => handleAccordionOpen('1')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What ISV verticals are you most interested in?</label>{" "}<span style={{ display: "inline-block", marginLeft: "1rem" }}>{formState?.preferred_industries?.length > 0 ? formState?.preferred_industries?.length + ' industry(ies) selected' : null}</span>
                                {
                                    industryError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {industryError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <IndustriesList
                                industriesSelected={formState?.preferred_industries}
                                addIndustry={addTargetedIndustry}
                                removeIndustry={removeTargetedIndustry}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => handleAccordionOpen('2')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What ISV horizontals are you most interested in?</label>{" "}
                                <span style={{ display: "inline-block", marginLeft: "1rem" }}>
                                    {(formState?.preferred_featured_software || []).concat(formState?.preferred_software || [])
                                        .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index)
                                        .length > 0 ?
                                        (formState?.preferred_featured_software || []).concat(formState?.preferred_software || [])
                                            .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index)
                                            .length + ' solution(s) selected'
                                        : null}
                                </span>

                                {
                                    solutionError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {solutionError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <SolutionsList
                                featuredSolutionSelected={formState?.preferred_featured_software}
                                solutionsSelected={formState?.preferred_software}
                                addFeaturedSolution={addFeaturedSolution}
                                addSoftwareSolution={addSoftwareSolution}
                                removeSolution={removeSolution}
                                removeItemSelected={removeItemSelected}
                            />
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => handleAccordionOpen('3')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Where should the ISV business be based?</label>{" "}<span style={{ display: "inline-block", marginLeft: "1rem" }}> {formState?.preferred_hq_location.length > 0 ? formState?.preferred_hq_location.length + '  countries selected' : null}</span>
                                {
                                    geographicSalesError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {geographicSalesError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required mb-4 p-0'>

                                <div className="geographicList">
                                    <Accordion defaultActiveKey={activeId}>
                                        <Countries
                                            geographicData={geographicData}
                                            activeId={activeId}
                                            toggleActive={toggleActive}
                                            data={formState?.preferred_hq_location}
                                            stateName={"preferred_hq_location"}
                                            updateState={updateState}
                                            isEdit={true}
                                        />
                                    </Accordion>

                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => handleAccordionOpen('4')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What type of opportunity are you most interested in? </label>{" "}<span style={{ display: "inline-block", marginLeft: "1rem" }}>{isDisplayOpportunity === false ? formState?.opportunity: ""}</span>
                                {
                                    opportunityError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            <div style={{cursor: "default"}} className="mt-3">
                            <span className="Tooltip text-white m-0">i</span> <p className="secondary-info d-inline-block mb-0">This information is used for matchmaking but will not be displayed on your published listing.</p>
                            </div>
                            </div>

                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required mb-4 p-0 mt-4'>
                                <select className='form-control mt-2' value={selectedOption.opportunity} onChange={(e) => { handleSelectChange(e, 'opportunity'); updateState('opportunity', e.target.value); }}>
                                    {renderOptions(opportunitiesData?.data)}
                                </select>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header onClick={() => handleAccordionOpen('5')}>
                            <div className='form-group required p-0'>
                                <label style={{ fontWeight: 600 }} htmlFor=''>What ecosystems/technology partnerships would enhance the appeal of an ISV? </label><span style={{ display: "inline-block", marginLeft: "1rem" }}></span>{isDisplayTechnology === false ? currentSelectedList?.length+" item(s) selected": ""}
                                {
                                    ecosystemError && (
                                        <div className="container-error pl-2  d-inline-block ml-2" style={{ color: 'red' }}>
                                            <span className='error-red fer'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                                <div style={{cursor: "default"}} className="mt-3">
                                <span className="Tooltip text-white m-0">i</span> <p className="secondary-info d-inline-block mb-0">This information is used for matchmaking but will not be displayed on your published listing.</p>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body id="mainterest" className='pt-0'>
                            <div className='form-group required p-0 my-4'>
                                <div className="paterneer position-relative mt-2">
                                    <CreatableSelect
                                        options={technologiesPartnership}
                                        className="input-text form-control p-0 mt-2"
                                        classNamePrefix="partneer"
                                        onChange={(el: any) => {
                                            setCurrentSelected(el);
                                        }}
                                        placeholder={currentSelectedList.length === 0 ? "Select all that apply" : ""}
                                        value={currentSelectedList?.length === 0 && currentSelected && currentSelected.label === '' ? 'Select all that apply' : currentSelected}

                                    />
                                    <button id="add-partneer" className='border-0 d-flex align-items-center' onClick={() =>
                                        isHaveSelectedLabel &&
                                        addListOption(currentSelected)
                                    }>
                                        <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="#fff" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>

                                    </button>
                                </div>
                                {currentSelectedList &&
                                    currentSelectedList.map(
                                        (el: { value: number; label: string }, index: number) => (
                                            <div className="paterneer d-flex mt-2" key={index}>
                                                <input
                                                    type="text"
                                                    className="input-text form-control"
                                                    placeholder=""
                                                    value={el.label}
                                                    disabled={true}
                                                />
                                                <button style={{ background: "#d9534f" }} className='border-0 red-button' onClick={() => removeSelected(el.value)}>
                                                    <svg width="30" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="#fff" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5'
                                onClick={() => handleCloseModal()}
                            >
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5 cancel'
                                onClick={() => handleCloseModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-devinsider save text-white'
                                onClick={() => handleSave()}
                            >
                                {isUpdating ? <Loader /> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer >
        </Modal>
    );
}
function forceUpdate() {
    throw new Error("Function not implemented.");
}

