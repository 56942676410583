import ISO3166 from 'iso-3166-1-alpha-2';
import { ChangeEvent, useEffect, useState } from "react";
import { Accordion, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CreatableSelect from "react-select/creatable";
import { useGetMySpecificTehnologyListing } from '../../../api/buyer';
import { generateYearList } from '../../../api/seller';
import { useGetStaticParams } from "../../../api/staticParams";
import IndustriesList from '../../../components/industriesList';
import SolutionsList from '../../../components/solutionsList';
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';
import { Countries } from "../../../widget/countries";
import { renderOptions } from '../utils/renderOptions';

type CountryItem = {
    countries: {
        iso2: string;
        country: string;
        continent: string;
    };
    region: string;
};
interface UserSpecificTechnology {
    id: number;
    idUser: string;
    specificTechnology: any[];
}
type SelectedOptions = {
    lifecycle: string[];
};

type EcosystemItem = {
    value: number | string;
    label: string;
    __isNew__?: boolean
};

export default function CompanyOverview(props: any) {
    const _ = require('lodash');

    const getInitialState = () => {
        return {
            ...props?.data,
            ma_intention: props?.data?.ma_intention !== 'manda_deleted_manda' ? props?.data?.ma_intention : '',
            challenge: props?.data?.challenge !== 'manda_deleted_manda' ? props?.data?.challenge : '',
            targeted_industry: props?.data?.targeted_industry?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.targeted_industry : [],
            software_solution: props?.data?.software_solution?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.software_solution : [],
            featured_solution: props?.data?.featured_solution?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.featured_solution : [],
            geographic_buyer: props?.data?.geographic_buyer?.find((el: any) => el !== "manda_deleted_manda") ? props?.data?.geographic_buyer : [],
            company_reason: props?.data?.company_reason !== 'manda_deleted_manda' ? props?.data?.company_reason : '',
            location: props?.data?.location !== 'manda_deleted_manda' ? props?.data?.location : '',
            founded: props?.data?.founded !== 'manda_deleted_manda' ? props?.data?.founded : '',
            company_size: props?.data?.company_size !== 'manda_deleted_manda' ? props?.data?.company_size : '',
            recent_acquisition: props?.data?.recent_acquisition !== 'manda_deleted_manda' ? props?.data?.recent_acquisition : '',
            main_acquisition_goal: props?.data?.main_acquisition_goal !== 'manda_deleted_manda' ? props?.data?.main_acquisition_goal : '',
            ma_budget_indication: props?.data?.ma_budget_indication !== 'manda_deleted_manda' ? props?.data?.ma_budget_indication : ''
        };
    }

    const [formState, setFormState] = useState(getInitialState());

    useEffect(() => {
        if (props?.isOpen) {
            setFormState(getInitialState());
        }
    }, [props?.isOpen]);

    useEffect(() => {
        if (Object.keys(formState).length === 0) {
            setFormState(props?.data ?? {});
        }
    }, [props?.data]);

    const [isUpdating, setIsUpdating] = useState<boolean>(props?.isUpdating || false);

    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    }

    const [selectedIndustry, setSelectedIndustry] = useState<string[]>(formState?.targeted_industry || []);
    const [selectedSolution, setSelectedSolution] = useState<string[]>(formState?.software_solution || []);
    const [selectedFeaturedSolution, setSelectedFeaturedSolution] = useState<string[]>(formState?.featured_solution || []);

    const [industryError, setIndustryError] = useState('');
    const [solutionError, setSolutionError] = useState('');
    const [Brief, setBrief] = useState(formState?.company_reason || '');

    const years = generateYearList();
    const [selectedFounded, setSelectedFounded] = useState<string | undefined>(formState?.founded);

    const [enableGetEmployees, setEnableGetEmployees] = useState(true);
    const [enableGetAcquisitions, setEnableGetAcquisitions] = useState(true);
    const [enableGetMainAcquisitions, setEnableGetMainAcquisitions] = useState(true);
    const [enableGetHorizontals, setEnableGetHorizontals] = useState(true);
    const [enableGetVerticals, setEnableGetVerticals] = useState(true);
    const [enableGetAcquisitionBudget, setEnableGetAcquisitionBudget] = useState(true);
    const [enableGetIsvBased, setEnableGetIsvBased] = useState(true);
    const [enableGetCountries, setEnableGetCountries] = useState(true);


    const { data: employeesData, isFetched: isEmployeesFetched } = useGetStaticParams('EMPLOYEES', enableGetEmployees);
    const { data: acquisitionsData, isFetched: isAcquisitionsFetched } = useGetStaticParams('ACQUISITIONS', enableGetAcquisitions);
    const { data: horizontals, isFetched: isHorizontalsFetched } = useGetStaticParams('HORIZONTALS', enableGetHorizontals);
    const { data: verticals, isFetched: isVerticalsFetched } = useGetStaticParams('VERTICALS', enableGetVerticals);
    const { data: mainAcquisition, isFetched: isMainAcquisitionsFetched } = useGetStaticParams('MAIN_PURPOSES', enableGetMainAcquisitions);
    const { data: acquisitionBudget, isFetched: isAcquisitionBudgetFetched } = useGetStaticParams('ACQUISITION_BUDGETS', enableGetAcquisitionBudget);
    const { data: isvBased, isFetched: isIsvBasedFetched } = useGetStaticParams('COUNTRIES_SORT_NAME', enableGetIsvBased);
    const { data: dataCountry, isFetched: isCountriesFetched } = useGetStaticParams('COUNTRIES', enableGetCountries);

    useEffect(() => {
        if (isEmployeesFetched) setEnableGetEmployees(false);
    }, [isEmployeesFetched]);

    useEffect(() => {
        if (isAcquisitionsFetched) setEnableGetAcquisitions(false);
    }, [isAcquisitionsFetched]);

    useEffect(() => {
        if (isHorizontalsFetched) setEnableGetHorizontals(false);
    }, [isHorizontalsFetched]);

    useEffect(() => {
        if (isVerticalsFetched) setEnableGetVerticals(false);
    }, [isVerticalsFetched]);

    useEffect(() => {
        if (isMainAcquisitionsFetched) setEnableGetMainAcquisitions(false);
    }, [isMainAcquisitionsFetched]);

    useEffect(() => {
        if (isAcquisitionBudgetFetched) setEnableGetAcquisitionBudget(false);
    }, [isAcquisitionBudgetFetched]);

    useEffect(() => {
        if (isIsvBasedFetched) setEnableGetIsvBased(false);
    }, [isIsvBasedFetched]);

    useEffect(() => {
        if (isCountriesFetched) setEnableGetCountries(false);
    }, [isCountriesFetched]);


    const [activeId, setActiveId] = useState<any>("null");
    const [activeGeoId, setActiveGeoId] = useState<any>("null");

    const [selectedOption, setSelectedOption] = useState({
        lifecycle: formState?.lifecycle_stage || '',
        isvBased: formState?.location || '',
        employe: formState?.company_size || '',
        acquisition: formState?.recent_acquisition || '',
        acquisitionBudget: formState?.ma_budget_indication || '',
        mainAcquisition: formState?.main_acquisition_goal || '',
    });

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isDisplayBrief, setIsDisplayBrief] = useState(false);
    const [isDisplayIsvBased, setIsDisplayIsvBased] = useState(false);
    const [isDisplayYear, setIsDisplayYear] = useState(false);
    const [isDisplayEmploye, setIsDisplayEmploye] = useState(false);
    const [isDisplayAcquisition, setIsDisplayAcquisition] = useState(false);
    const [isDisplayMainAcquisition, setIsDisplayMainAcquisition] = useState(false);
    const [isDisplayAcquisitionBudget, setIsDisplayAcquisitionBudget] = useState(false);
    const [isDisplaySolution, setIsDisplaySolution] = useState(false);
    const [isDisplayIndustry, setIsDisplayIndustry] = useState(false);
    const [isDisplayGeography, setIsDisplayGeography] = useState(false);
    const [isDisplayTechnology, setIsDisplayTechnology] = useState(false);
    const maxLength = 1000;
    const maxDisplay = 200;

    const handleChange = (e: { target: { value: any; }; }) => {
        const inputValue = e.target.value;
        if (inputValue.length <= maxLength) {
            setBrief(inputValue);
            updateState('company_reason', inputValue);
        }
    };

    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
        switch (eventKey) {
            case '0':
                setIsAccordionOpen(true);
                setIsDisplayBrief(!isDisplayBrief);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '1':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(!isDisplayIsvBased);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '2':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(!isDisplayYear);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '3':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(!isDisplayEmploye);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '4':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(!isDisplayAcquisition);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '5':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(!isDisplayMainAcquisition);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '6':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(!isDisplayAcquisitionBudget);
                setIsDisplayTechnology(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
            case '7':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayIndustry(!isDisplayIndustry);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                setIsDisplayTechnology(false);
                break;
            case '8':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(!isDisplaySolution);
                setIsDisplayGeography(false);
                setIsDisplayTechnology(false);
                break;
            case '9':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(!isDisplayGeography);
                setIsDisplayTechnology(false);
                break;
            case '10':
                setIsAccordionOpen(true);
                setIsDisplayBrief(false);
                setIsDisplayIsvBased(false);
                setIsDisplayYear(false);
                setIsDisplayEmploye(false);
                setIsDisplayAcquisition(false);
                setIsDisplayMainAcquisition(false);
                setIsDisplayAcquisitionBudget(false);
                setIsDisplayTechnology(!isDisplayTechnology);
                setIsDisplayIndustry(false);
                setIsDisplaySolution(false);
                setIsDisplayGeography(false);
                break;
        }
    };

    const setActiveSection = (eventKey: string | null) => {
        handleAccordionOpen(eventKey);
        toggleActive(eventKey);
    };

    const [briefError, setBriefError] = useState('');
    const [companyHeadCarterError, setCompanyHeadCarterError] = useState('');
    const [yearError, setYearError] = useState('');
    const [employeError, setEmployeError] = useState('');
    const [ecosystemError, setEcosystemError] = useState('');
    const [acquisitionError, setAcquisitionError] = useState('');
    const [acquisitionBudgetError, setAcquisitionBudgetError] = useState('');
    const [mainAcquisitionError, setMainAcquisitionError] = useState('');
    const [geographicSalesError, setGeographicSalesError] = useState('');
    const [haveEcosystemError, setHaveEcosystemError] = useState('');

    useEffect(() => {
        setSelectedIndustry(formState?.targeted_industry || []);
        setSelectedSolution(formState?.software_solution || []);
        setSelectedFeaturedSolution(formState?.featured_solution || []);
        setSelectedFounded(formState?.founded || '');
        setSelectedOption({
            lifecycle: formState?.lifecycle_stage || '',
            isvBased: formState?.location || '',
            employe: formState?.company_size || '',
            acquisition: formState?.recent_acquisition || '',
            acquisitionBudget: formState?.ma_budget_indication || '',
            mainAcquisition: formState?.main_acquisition_goal || '',
        });
        setBrief(formState?.company_reason || '');
    }, [formState]);


    const validateForm = () => {
        let hasError = false;
        if (!Brief) {
            setBriefError(' ERROR This field is required');
            hasError = true;
        }
        if (!selectedOption.isvBased || selectedOption.isvBased === '' || selectedOption.isvBased === 'Select an option...') {
            setCompanyHeadCarterError(' ERROR This field is required');
            hasError = true;
        }
        if (!selectedFounded) {
            setYearError(' ERROR This field is required');
            hasError = true;
        }

        if (!selectedOption.employe || selectedOption.employe === '' || selectedOption.employe === 'Select an option...') {
            setEmployeError(' ERROR This field is required');
            hasError = true;
        }
        if (!selectedOption.acquisition || selectedOption.acquisition === '' || selectedOption.acquisition === 'Select an option...') {
            setAcquisitionError(' ERROR This field is required');
            hasError = true;
        }

        if (!selectedOption.mainAcquisition || selectedOption.mainAcquisition === '' || selectedOption.mainAcquisition === 'Select an option...') {
            setMainAcquisitionError(' ERROR This field is required');
            hasError = true;
        }




        if (!selectedIndustry || selectedIndustry.length < 1) {
            setIndustryError(' ERROR This field is required');
            hasError = true;
        }

        if ((!selectedSolution && selectedFeaturedSolution) || [...selectedSolution, ...selectedFeaturedSolution].length < 1) {
            setSolutionError(' ERROR This field is required');
            hasError = true;
        }
        if (formState?.geographic_buyer && formState?.geographic_buyer?.length == 0) {
            setGeographicSalesError(' ERROR This field is required');
            hasError = true;
        }

        return hasError;
    }

    useEffect(() => {
        if (Brief && Brief.trim() !== '') {
            setBriefError('');
        }

        if (selectedOption.employe !== undefined && selectedOption.employe !== 'Select an option...' && selectedOption.employe !== '') {
            setEmployeError('');
        }
        if (selectedIndustry.length > 0) {
            setIndustryError('');

        }
        if (selectedSolution.length > 0 || selectedFeaturedSolution.length > 0) {
            setSolutionError('');
        }
        if (selectedFounded !== undefined && selectedFounded !== 'Select an option...' && selectedFounded !== '') {
            setYearError('');
        }

        if (formState?.geographic_buyer && formState.geographic_buyer?.length > 0) {
            setGeographicSalesError('');
        }

        if (selectedOption.isvBased !== undefined && selectedOption.isvBased !== 'Select an option...' && selectedOption.isvBased !== '') {
            setCompanyHeadCarterError('');
        }
        if (selectedOption.acquisition !== undefined && selectedOption.acquisition !== 'Select an option...' && selectedOption.acquisition !== '') {
            setAcquisitionError('');
        }

        if (selectedOption.mainAcquisition !== undefined && selectedOption.mainAcquisition !== 'Select an option...' && selectedOption.mainAcquisition !== '') {
            setMainAcquisitionError('');
        }


    }, [selectedOption, Brief]);

    let allSolution = [...selectedFeaturedSolution, ...selectedSolution];

    const handleFoundedChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = evt.target.value;
        setSelectedFounded(value);
        updateState('founded', value);
    };
    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, property: string) => {
        const value = event.target.value;
        setSelectedOption((prevState) => ({ ...prevState, [property]: value }));
    };

    function toggleActive(id: any) {
        if (activeId === id) {
            setActiveId("null");
        } else {
            setActiveId(id);
        }
    };

    const geographicData = dataCountry && dataCountry
        ? dataCountry.data.reduce((acc: any, current: any) => {
            if (current && current.attributes) {
                const continent = current.attributes.Continent;
                const iso2 = current.attributes.ISO2;
                const name = current.attributes.Name;

                if (continent && iso2 && name) {
                    if (!acc[continent]) {
                        acc[continent] = {
                            Continent: continent,
                            Countries: [{ ISO2: iso2, Name: name }],
                        };
                    } else {
                        acc[continent].Countries.push({ ISO2: iso2, Name: name });
                    }
                }
            }
            return acc;
        }, {})
        : {};

    const spanContent = isDisplayBrief === false && Brief != '' ? Brief.substring(0, maxDisplay) + "..." : "";

    const addTargetedIndustry = (element: any) => {
        const updatedSelectedIndustry = [...selectedIndustry, element?.attributes?.Label];
        setSelectedIndustry(updatedSelectedIndustry);
        updateState('targeted_industry', updatedSelectedIndustry);
    }

    const removeTargetedIndustry = (element: any) => {
        const updatedSelectedIndustry = selectedIndustry?.filter(
            (el: any) => el !== element?.attributes?.Label
        );
        setSelectedIndustry(updatedSelectedIndustry);
        updateState('targeted_industry', updatedSelectedIndustry);
    }

    const addSoftwareSolution = (element: any) => {
        updateState('software_solution', [...formState?.software_solution, element?.attributes?.Label]);
    }

    const addFeaturedSolution = (element: any) => {
        updateState('featured_solution', [...formState?.featured_solution, element?.attributes?.Label]);
    }

    const removeSolution = (element: any) => {
        updateState('software_solution', formState?.software_solution?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));

        updateState('featured_solution', formState?.featured_solution?.filter(
            (el: any) => el !== element?.attributes?.Label
        ));
    }

    const removeItemSelected = (element: any) => {
        updateState('software_solution', formState?.software_solution?.filter(
            (el: any) => el !== element
        ));

        updateState('featured_solution', formState?.featured_solution?.filter(
            (el: any) => el !== element
        ));
    }



    const handleCloseModal = () => {

        setFormState({ ...props?.data });
        setIsAccordionOpen(false);
        setIsDisplayBrief(false);
        setIsDisplayIsvBased(false);
        setIsDisplayYear(false);
        setIsDisplayEmploye(false);
        setIsDisplayAcquisition(false);
        setIsDisplayMainAcquisition(false);
        setIsDisplayAcquisitionBudget(false);
        setIsDisplayTechnology(false);
        setIsDisplayIndustry(false);
        setIsDisplaySolution(false);
        setIsDisplayGeography(false);
        props?.closeModal();

    };

    const handleSave = async () => {
        setIsUpdating(!props?.isUpdating);
        const stepHasErrors = validateForm();
        if (stepHasErrors) {
            setIsUpdating(false);
            return
        }
        await props?.confirmSave(formState);
        setIsUpdating(false);
        setIsAccordionOpen(false);
        setIsDisplayBrief(false);
        setIsDisplayIsvBased(false);
        setIsDisplayYear(false);
        setIsDisplayEmploye(false);
        setIsDisplayAcquisition(false);
        setIsDisplayMainAcquisition(false);
        setIsDisplayAcquisitionBudget(false);
        setIsDisplayTechnology(false);
        setIsDisplayIndustry(false);
        setIsDisplaySolution(false);
        setIsDisplayGeography(false);
        setIsAccordionOpen(false);
    };



    return (
        <Modal show={props?.isOpen} className='createCompanypopup large forStep5custom edit-modal buyer-modal'>
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{ fontSize: "1rem", color: "#656565" }} className='title'>{props?.title}</h2>

                    <button onClick={() => handleCloseModal()} className='close'>
                        <span className='icon-close-pop'></span>

                    </button>
                </div>
            </Modal.Header>
            <Modal.Body id="mainterest" className='overview-buyer'>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => handleAccordionOpen('0')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Company summary:</label>{" "}<span>{spanContent}</span>
                                {
                                    briefError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {briefError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor='Brief'>Outline a brief overview of your company </label> */}
                            
                                <textarea
                                    id='Brief'
                                    className='form-control mt-2'
                                    maxLength={1000}
                                    value={formState?.company_reason}
                                    onChange={handleChange}
                                />
                            </div>
                            {Brief.length}/1000
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => handleAccordionOpen('1')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Where is your company headquartered?</label><span>{isDisplayIsvBased === false ? ISO3166.getCountry(formState?.location) : ""}</span>
                                {
                                    companyHeadCarterError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {companyHeadCarterError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                <select className='form-control mt-2' value={selectedOption.isvBased} onChange={(e) => { handleSelectChange(e, 'isvBased'); updateState('location', e.target.value); }}>
                                    {renderOptions(isvBased?.data)}
                                </select>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => handleAccordionOpen('2')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>In what year was your company founded?</label>{" "}<span>{isDisplayYear === false ? formState?.founded : ""}</span>
                                {
                                    yearError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {yearError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor=''>In what year was your business founded?</label> */}
                                <select
                                    className='form-control mt-2'
                                    name='founded'
                                    onChange={(e) => { handleFoundedChange(e); updateState('founded', e.target.value); }}
                                    value={formState?.founded || ''}
                                >
                                    <option value="">Select an option ...</option>
                                    {
                                        years?.map((el: any, index: any) => (
                                            <option key={index} value={el}>{el}</option>
                                        ))
                                    }
                                </select>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header onClick={() => handleAccordionOpen('3')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>How many people does your company employ?</label>{" "}<span>{isDisplayEmploye === false ? formState?.company_size : ""}</span>
                                {
                                    employeError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {employeError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor=''>How many people does your company employ? </label> */}
                                <select className='form-control mt-2' value={selectedOption.employe} onChange={(e) => { handleSelectChange(e, 'employe'); updateState('company_size', e.target.value); }}>
                                    {renderOptions(employeesData?.data)}
                                </select>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header onClick={() => handleAccordionOpen('4')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Specify how may acquisitions you have made in the past 5 years</label>{" "}<span>{isDisplayAcquisition === false ? formState?.recent_acquisition : ""}</span>
                                {
                                    acquisitionError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {acquisitionError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor=''>Specify how many acquisitions you have made in the past 5 years</label> */}
                                <select className='form-control mt-2' value={selectedOption.acquisition} onChange={(e) => { handleSelectChange(e, 'acquisition'); updateState('recent_acquisition', e.target.value); }}>
                                    {renderOptions(acquisitionsData?.data)}
                                </select>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header onClick={() => handleAccordionOpen('5')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What would be the main purpose of acquisition?</label>{" "}<span>{isDisplayMainAcquisition === false ? formState?.main_acquisition_goal : ""}</span>
                                {
                                    mainAcquisitionError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {mainAcquisitionError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0'>
                                {/* <label htmlFor=''>What would be the main purpose of acquisition?</label> */}
                                <select className='form-control mt-2' value={selectedOption.mainAcquisition} onChange={(e) => { handleSelectChange(e, 'mainAcquisition'); updateState('main_acquisition_goal', e.target.value); }}>
                                    {renderOptions(mainAcquisition?.data)}
                                </select>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header onClick={() => handleAccordionOpen('6')}>
                            <div className='form-group not-required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What is your overall acquisition budget?</label>{" "}<span className='not'>{isDisplayAcquisitionBudget === false ? formState?.ma_budget_indication : ""}</span>
                                {
                                    props?.errorSummarize && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> ERROR </span>{" "}
                                            <span className='txt-error'>This field is required</span>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group not-required mb-4 p-0'>
                                {/* <label htmlFor=''>What is your overall acquistion budget? </label> */}
                                <select className='form-control mt-2' value={selectedOption.acquisitionBudget} onChange={(e) => { handleSelectChange(e, 'acquisitionBudget'); updateState('ma_budget_indication', e.target.value); }}>
                                    {renderOptions(acquisitionBudget?.data)}
                                </select>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header onClick={() => setActiveSection('7')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What vertical industry(ies) do you serve?</label>{" "}<span>{formState?.targeted_industry?.length > 0 ? formState.targeted_industry.length + ' industry(ies) selected' : null}</span>

                                {
                                    industryError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {industryError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <IndustriesList
                                industriesSelected={formState?.targeted_industry}
                                addIndustry={addTargetedIndustry}
                                removeIndustry={removeTargetedIndustry}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header onClick={() => setActiveSection('8')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What horizontal solution(s) do you provide?</label>{" "}
                                <span>
                                    {isDisplaySolution === false &&
                                        allSolution
                                            .filter((value, index, self) => self.indexOf(value) === index)
                                            .length > 0 ?
                                        allSolution
                                            .filter((value, index, self) => self.indexOf(value) === index)
                                            .length + ' solution(s) selected'
                                        : null}
                                </span>


                                {
                                    solutionError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {solutionError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <SolutionsList
                                featuredSolutionSelected={formState?.featured_solution}
                                solutionsSelected={formState?.software_solution}
                                addFeaturedSolution={addFeaturedSolution}
                                addSoftwareSolution={addSoftwareSolution}
                                removeSolution={removeSolution}
                                removeItemSelected={removeItemSelected}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9" id='accord'>
                        <Accordion.Header onClick={() => setActiveSection('9')}>
                            <div className='form-group required p-0 overview'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>What is your geographic sales footprint?</label>{" "}<span>{formState?.geographic_buyer?.length > 0 ? formState?.geographic_buyer?.length + ' country(ies) selected' : null}</span>

                                {
                                    geographicSalesError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red' }}>
                                            <span className='error-red'> {geographicSalesError} </span>{" "}
                                        </div>
                                    )
                                }
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group required p-0 geographic-container'>
                                <p style={{ color: "#444" }}>Select all that apply</p>
                                <div className="geographicList">
                                    <Accordion defaultActiveKey={activeId}>
                                        <Countries
                                            geographicData={geographicData}
                                            activeId={activeId}
                                            toggleActive={toggleActive}
                                            data={formState?.geographic_buyer}
                                            stateName={"geographic_buyer"}
                                            updateState={updateState}
                                            isEdit={true}
                                        />
                                    </Accordion>

                                </div>
                            </div>
                        </Accordion.Body>

                    </Accordion.Item>

                </Accordion>

            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5'
                                onClick={() => handleCloseModal()}
                            >
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5 cancel'
                                onClick={() => handleCloseModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-devinsider save text-white'
                                onClick={() => handleSave()}
                            >
                                {isUpdating ? <Loader /> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer >
        </Modal>
    );
}
function forceUpdate() {
    throw new Error("Function not implemented.");
}

