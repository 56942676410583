import { useEffect, useRef, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { urlsApi } from "../../../api/urls/apiUrl";
import { useKeycloakHooks } from "../../../hooks/keycloak";
import { ReactComponent as Loader } from '../../../resources/images/Rolling.svg';

type DataParamType = {
    [key: string]: string | number | boolean | string[] | undefined;
};


export default function CompanyEdit(props: any) {
    const { keycloak, tokenInfo } = useKeycloakHooks();

    const getInitialState = () => {
        return {
            ...props?.data,
            company_name: props?.data?.company_name === 'user_anonyma' ? '' : props?.data?.company_name || '',
            company_url: props?.data?.company_url === 'web_url_anonyma' ? '' : props?.data?.company_url || '',
            company_image: props?.data?.company_image === '' ? '' : props?.data?.company_image || '',
        };
    }

    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [formState, setFormState] = useState(getInitialState());
    const [uploadedFileName, setUploadedFileName] = useState<string | null>('');

    const imageCompanyUploadbuyer = async (formData: FormData) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + urlsApi.upload.upload_image, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            });
            const data = await response.json();
            return data;
        } catch (error) {

        }
    };


    useEffect(() => {
        if (props?.isOpen) {
            setFormState(getInitialState());
        }
    }, [props?.isOpen]);
    const [isUpdating, setIsUpdating] = useState<boolean>(props?.isUpdating || false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [isDisplayCompanyName, setIsDisplayCompanyName] = useState(false);
    const [isDisplayCompanyWebsite, setIsDisplayWebsite] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const imagePreviewRef = useRef<HTMLImageElement>(null);
    const [CompanyName, setCompanyName] = useState(props?.data?.company_name === 'user_anonyma' ? '' : props?.data?.company_name || '');
    const [CompanyWebSite, setCompanyWebSite] = useState(props?.data?.company_url === 'web_url_anonyma' ? '' : props?.data?.company_url || '');
    const updateState = (property: string, value: any) => {
        setFormState((prevState: any) => ({
            ...prevState,
            [property]: value,
        }));
    };


    let selectedFile: File | null = null;
    useEffect(() => {
        if (selectedFile) {
            const maxSize = 10 * 1024 * 1024;

            if (selectedFile.size > maxSize) {
                setImageError('Image size should not exceed 10 MB.');
            } else {
                setImageError('');
            }
        }
    }, [selectedFile]);
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];

        if (selectedFile) {
            const maxSize = 10 * 1024 * 1024;

            if (selectedFile.size > maxSize) {
                setImageError('Image size should not exceed 10 MB.');
                return;
            }

            setIsSelecting(true);

            const reader = new FileReader();

            reader.onload = (event) => {
                const image = new Image();

                image.onload = async () => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    const width = image.width;
                    const height = image.height;

                    canvas.width = width;
                    canvas.height = height;

                    context?.drawImage(image, 0, 0, width, height);

                    updateState("company_image", selectedFile);

                    if (imagePreviewRef.current) {
                        imagePreviewRef.current.src = canvas.toDataURL('image/jpeg');
                    }
                };

                image.src = event.target?.result as string;
            };

            reader.readAsDataURL(selectedFile);
        } else {
            setIsSelecting(false);
        }
    };






    const [CompanyWebSiteError, setCompanyWebSiteError] = useState('');
    const [imageError, setImageError] = useState('');
    const resetFileInputRef = () => {
        setIsSelecting(false);
        if (imagePreviewRef.current) {
            imagePreviewRef.current.src = '';
        }
        updateState("company_image", '');
    };
    const handleAccordionOpen = (eventKey: string | null) => {
        if (eventKey && eventKey !== isAccordionOpen.toString()) {
            setIsAccordionOpen(true);
        }
        switch (eventKey) {
            case '1':
                setIsAccordionOpen(true);
                setIsDisplayCompanyName(!isDisplayCompanyName);
                setIsDisplayWebsite(false);
                break;
            case '2':
                setIsAccordionOpen(true);
                setIsDisplayCompanyName(false);
                setIsDisplayWebsite(!isDisplayCompanyWebsite);
                break;
        }
    };

    const handleCloseModal = () => {
        setFormState({ ...props?.data });
        setIsSelecting(false);
        setIsDisplayCompanyName(false);
        setIsDisplayWebsite(false);
        setIsAccordionOpen(false);
        props?.closeModal();

    };

    const validateForm = () => {
        let hasError = false;

        if (formState?.company_url) {
            const urlPattern = /^(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,6})+$/;

            if (!urlPattern.test(formState?.company_url)) {
                setCompanyWebSiteError(' ERROR Please enter a valid website format (e.g., www.example.com)');
                hasError = true;
            } else {
                setCompanyWebSiteError('');
            }
        }
        if (selectedFile) {
            const maxSize = 2 * 1024 * 1024;

            if (selectedFile.size > maxSize) {
                hasError = true;
            }
        }
        return hasError;
    }

    useEffect(() => {
        if (formState?.company_url) {
            const urlPattern = /^(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,6})+$/;

            if (urlPattern.test(formState?.company_url)) {
                setCompanyWebSiteError('');
            }
        }

    }, [formState?.company_url]);



    const handleSave = async () => {
        setIsUpdating(!props?.isUpdating);

        if (formState?.company_image && (formState?.company_image instanceof File || formState?.company_image instanceof Blob)) {
            const formData = new FormData();
            formData.append('file', formState?.company_image);
            const dataUploaded = await imageCompanyUploadbuyer(formData);
            const updatedcompany_image = dataUploaded?.file_name;

            const dataUpdateParam: DataParamType = {
                ...formState,
                company_image: updatedcompany_image
            };

            const stepHasErrors = validateForm();
            if (stepHasErrors) {
                setIsUpdating(false);
                return;
            }


            await props?.confirmSave(dataUpdateParam);

            setIsUpdating(false);
            setIsSelecting(false);
            setIsDisplayCompanyName(false);
            setIsDisplayWebsite(false);
            setIsAccordionOpen(false);
        }
        else  {
            await props?.confirmSave(formState);
            setIsUpdating(false);
            setIsSelecting(false);
            setIsDisplayCompanyName(false);
            setIsDisplayWebsite(false);
            setIsAccordionOpen(false);
        }
    };


    const handleSelectLogo = () => {

        fileInputRef.current?.click();

    };

    return (

        <Modal show={props?.isOpen} id="buyer-edit" className='createCompanypopup large forStep5custom buyer-modal'>
            <Modal.Header>
                <div className='headerpopupCompany noStep'>
                    <h2 style={{ fontSize: "1rem", color: "#656565" }} className='title'>{props?.title}</h2>

                    <button onClick={() => handleCloseModal()}
                        className="close">
                        <span className="icon-close-pop" aria-hidden="true"></span>

                    </button>
                </div>
            </Modal.Header>

            <Modal.Body className='company'>

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => handleAccordionOpen('0')}>
                            {/* <div id="buyer-edit-logo" className='form-group mb-4 p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Company Logo</label> <>
                                    {
                                        formState?.company_image !== null && formState?.company_image !== undefined && formState?.company_image !== "cons" && formState?.company_image !== "" ? (
                                            <img src={imageSrc}
                                                width={50}
                                                height={60}
                                                alt="Company Logo" className="ico" />
                                        ) : (
                                            <span className='icon-building ico'></span>
                                        )
                                    }
                                </><br></br>
                            </div> */}
                            {
                                isSelecting == false ? (
                                    <div id="buyer-edit-logo" className='form-group p-0 mt-0 mb-3'>
                                        <label htmlFor='' style={{ fontWeight: 600 }}>Company Logo</label> <></>
                                        {
                                            formState?.company_image !== null && formState?.company_image !== undefined && formState?.company_image !== "" && formState?.company_image !== "" ? (
                                                <img src={process.env.REACT_APP_API_URL + urlsApi.upload.get_upload_image + '/' + formState?.company_image}
                                                    width={80}
                                                    height={80}
                                                    alt="Company Logo" />
                                            ) : (
                                                <span className='icon-building ico'></span>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <>
                                        {
                                            imageError && (
                                                <div className="container-error pl-2  d-inline" style={{ color: 'red', marginLeft: "6rem" }}>
                                                    <span className='error-red'> {imageError} </span>{" "}
                                                </div>
                                            )
                                        }
                                        <img
                                            id="logoEditPreview"
                                            ref={imagePreviewRef}
                                            style={{ maxWidth: "40%" }}
                                            width={80}
                                            height={80}
                                            alt="Selected Logo Preview"
                                        />
                                    </>
                                )
                            }
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>

                            </div>

                            <div className='mt-3'>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(e)}
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                />
                                <button
                                    style={{ color: "#f60", marginLeft: 0 }}
                                    className='btn btn-devinsider save bg-transparent'
                                    onClick={() => handleSelectLogo()}
                                >
                                    Select logo
                                </button>
                                <button
                                    style={{ marginLeft: "1rem" }}
                                    className='btn btn-devinsider px-5 cancel'
                                    onClick={() => resetFileInputRef()}
                                >
                                    Delete logo
                                </button>
                            </div>

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header onClick={() => handleAccordionOpen('1')}>
                            <div className='form-group p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Company Name:</label>{" "}<span>{isDisplayCompanyName === false && formState?.company_name !== "user_anonyma" ? formState?.company_name : ""}</span>

                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group not-required mb-4 p-0'>
                                <label htmlFor=''>What is your company name? </label>
                                <input maxLength={450}
                                    value={formState?.company_name}
                                    onChange={(e) => {
                                        setCompanyName(e.target.value);
                                        updateState('company_name', e.target.value);
                                    }} className='form-control mt-2' type="text" required name="" id="" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header onClick={() => handleAccordionOpen('2')}>
                            <div className='form-group required p-0'>
                                <label htmlFor='' style={{ fontWeight: 600 }}>Company website:</label>{" "}<span>{isDisplayCompanyWebsite === false ? formState?.company_url : ""}</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className='form-group not-required mb-4 p-0'>
                                <label htmlFor=''>What is your company website? </label>
                                {
                                    CompanyWebSiteError && (
                                        <div className="container-error pl-2  d-inline" style={{ color: 'red', marginLeft: "6rem" }}>
                                            <span className='error-red'> {CompanyWebSiteError} </span>{" "}
                                        </div>
                                    )
                                }
                                <input maxLength={450}
                                    value={formState?.company_url}
                                    onChange={(e) => {
                                        setCompanyWebSite(e.target.value);
                                        updateState('company_url', e.target.value);
                                    }} className='form-control mt-2' type="text" required name="" id="" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


            </Modal.Body>
            <Modal.Footer>
                <div className='contentBorder formForEmail'>
                    {!isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5'
                                onClick={() => handleCloseModal()}
                            >
                                Close
                            </button>
                        </>
                    )}
                    {isAccordionOpen && (
                        <>
                            <button
                                style={{ marginLeft: "1rem" }}
                                className='btn btn-devinsider px-5 cancel'
                                onClick={() => handleCloseModal()}
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-devinsider save text-white'
                                onClick={() => handleSave()}
                            >
                                {isUpdating ? <Loader /> : "Save"}
                            </button>
                        </>
                    )}
                </div>
            </Modal.Footer >

        </Modal >
    );
}
