"use client";

import ISO3166 from 'iso-3166-1-alpha-2';
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useGetFiltersListing, useGetSellerListe } from '../../../api/seller';
import { useGetTypeUser } from "../../../api/user";
import EnrollBuyer from '../../../components/modals/enroll/enroll-buyer';
import SeeBtn from "../../../components/seeBtn";
import { useKeycloakHooks } from '../../../hooks/keycloak';
import SellerSecondMenu from '../../../layouts/second-headers/seller';
import { addToArray, paginate } from '../../../utils/outils';
import { LoaderCommon } from '../../../widget/loader';
import "../seller.scss";

interface Listing {
  showListing?: boolean
}

export default function SellerListing(props: Listing) {
  const navigate = useNavigate();
  const [limitSolution, setLimitSolution] = useState(6);
  const [limitIndustry, setLimitIndustry] = useState(6);
  const [limitLocation, setLimitLocation] = useState(6);
  const [selectedSolution, setSelectedSolution] = useState<Array<string>>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<Array<string>>([]);
  const [selectedLocation, setSelectedLocation] = useState<Array<string>>([]);
  const [enrollBuyer, setEnrollBuyer] = useState(false);
  const [hasListing, setHasListing] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const { keycloak, tokenInfo, initialized } = useKeycloakHooks();
  const { data: userRoles, isLoading: userRolesLoading } = useGetTypeUser(tokenInfo, enabled);

  const [searchInput, setSearchInput] = useState<string>('');
  const [params, setParams] = useState({
    location: [] as string[],
    horizontal: [] as string[],
    vertical: [] as string[],
  });

  const { data, isLoading: sellerListingsLoading } = useGetSellerListe(enabled, params);
  const { data: filtersData, isLoading: filtersLoading } = useGetFiltersListing(enabled);


  const [isLocationOpen, setIsLocationOpen] = useState(true);
  const [isSolutionOpen, setIsSolutionOpen] = useState(true);
  const [isIndustryOpen, setIsIndustryOpen] = useState(true);

  const toggleFilterSection = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
    setState(prevState => !prevState);
  };

  useEffect(() => {
    if (initialized && keycloak.authenticated && userRoles) {
      if ((userRoles?.isSeller || userRoles?.isBuyer) && !props?.showListing) {
        setHasListing(true);
      }
    }
  }, [userRoles]);

  useEffect(() => {
    if (params) setEnabled(true);
    if (userRoles && data && filtersData) setEnabled(false);
  }, [params, data, filtersData, userRoles])

  const goToLogin = () => {
    keycloak.login();
  };

  const goToBuyerListing = () => {
    if(props?.showListing) {
      navigate("/seller/listing/my-matching");
    } else {
      navigate("/buyer/listing");
    }
  };

  const goToSellerListing = () => {
    navigate("/seller/listing");
  };

  const goToEnrollHomePage = () => {
    navigate("/enroll");
  };

  const goToEnrollAsBuyer = () => {
    navigate("/buyer/listing/creation");
  };

  const goToDetailListing = (idSeller?: any, idUser?: any) => {
    if (keycloak?.authenticated) {
      if (userRoles && idSeller) {
        if (userRoles?.isBuyer || (idUser && idUser === tokenInfo?.user_uuid)) navigate(`/seller/listing/details/${idSeller}`);
        else setEnrollBuyer(true);
      }
    } else {
      goToLogin();
    }
  }


  const handleSeeSolution = (action: string) => {
    setLimitSolution(action === '0' ? filtersData?.software_solution.length : 6);
  };

  const handleSeeIndustry = (action: string) => {
    setLimitIndustry(action === '0' ? filtersData?.targeted_industry.length : 6);
  };

  const handleSeeLocation = (action: string) => {
    setLimitLocation(action === '0' ? filtersData?.location.length : 6);
  };

  const handleSelectSolution = (element: string) => {
    setSelectedSolution(addToArray(selectedSolution, element));
  }

  const handleSelectIndustry = (element: string) => {
    setSelectedIndustry(addToArray(selectedIndustry, element));
  }

  const handleSelectLocation = (element: any) => {
    setSelectedLocation(addToArray(selectedLocation, element));
  }

  const filterSellerListing = () => {
    let param = {
      location: selectedLocation,
      horizontal: selectedSolution,
      vertical: selectedIndustry,
    }

    setParams((prevParams) => ({
      ...prevParams,
      ...param
    }));
  }

  const resetSellerListing = () => {
    let param = {
      location: [],
      horizontal: [],
      vertical: [],
    }
    setSearchInput('');
    setSelectedSolution([]);
    setSelectedLocation([]);
    setSelectedIndustry([]);

    setParams((prevParams) => ({
      ...prevParams,
      ...param
    }));

  }

  // const hasSellersListingAndNoSearchAndIsSellerBuyer = data && data?.sellers_listing && data?.sellers_listing?.length > 0 && searchInput.trim() === '' ;

  // const hasSellersListingAndFiltersAndIsSellerBuyer =  data && data?.sellers_listing && data?.sellers_listing?.length > 0 && filtersData && filtersData.length > 0 && (userRoles && userRoles?.isSeller && userRoles?.isBuyer);

  // const hasSellersListingAndSearchAndIsSellerBuyer =  data && data?.sellers_listing && data?.sellers_listing?.length > 0 && searchInput.trim() !== '' && handleSearch().length > 0 && (userRoles && userRoles?.isSeller && userRoles?.isBuyer);

  // const hasSellersListingAndNoSearchAndIsBuyer =  data && data?.sellers_listing && data?.sellers_listing?.length > 0 && searchInput.trim() !== '' && handleSearch().length > 0 ;

  // const hasSellersListingAndSearchAndIsBuyer =  data && data?.sellers_listing && data?.sellers_listing?.length > 0 && searchInput.trim() !== '' && handleSearch().length > 0 && (userRoles && userRoles?.isBuyer);



  const hasFilter = filtersData && filtersData.location && filtersData.location.length > 0 && filtersData.software_solution && filtersData.software_solution.length > 0 && filtersData.targeted_industry && filtersData.targeted_industry.length > 0;


  let shouldDisplayBlock = false;

  if (hasFilter) {
    shouldDisplayBlock = true;
  }

  const handleGoToFavorite = () => {
    if (keycloak?.authenticated) { }
    else keycloak?.login();
  }

  const handleEnroll = () => {
    if (keycloak?.authenticated) {
      if (userRoles) {
        if (!userRoles?.isSeller) goToEnrollHomePage();
        else goToEnrollAsBuyer();
      }
    } else {
      goToLogin();
    }
  }

  const isOpportunistic = (el: any) => {
    var isOppo = false;

    if (el?.urgency === "Opportunistic" || el?.reason_listing === "I'm opportunistic and open to receive inquiries from potential buyers") {
      isOppo = true
    }

    return isOppo;
  }

  const isPremium = (el: any) => {
    return el?.user_account?.is_premium === true;
  };


  const [sortedListings, setSortedListings] = useState([]);

  useEffect(() => {
    if (data && data.sellers_listing) {
      const premiumListings = data.sellers_listing.filter((el: any) => isPremium(el));
      const opportunisticListings = data.sellers_listing.filter((el: any) => !isPremium(el));
      const sortedListings = premiumListings.concat(opportunisticListings);
      setSortedListings(sortedListings);
    }
  }, [data]);

  const handleSearch = () => {
    if (searchInput.trim() !== '') {
      const filteredData = sortedListings.filter((el: any) => {
        const country = ISO3166.getCountry(el?.location);
        const locationMatches = country && country.toLowerCase().includes(searchInput.toLowerCase());
        const founded = el?.founded.toLowerCase().includes(searchInput.toLowerCase());
        const stage = el?.stage.toLowerCase().includes(searchInput.toLowerCase());
        const reason = el?.company_reason.toLowerCase().includes(searchInput.toLowerCase());
        const valueDriver = el?.value_driver.toLowerCase().includes(searchInput.toLowerCase());
        const companySize = el?.company_size.toLowerCase().includes(searchInput.toLowerCase());
        const valueAtion = el?.valuation.toLowerCase().includes(searchInput.toLowerCase());
        const profbility = el?.profitability.toLowerCase().includes(searchInput.toLowerCase());
        return locationMatches || founded || stage || reason || valueDriver || companySize || valueAtion || profbility;
      });

      return filteredData || [];
    }

    return data || [];
  };


  return (
    <>
      {(initialized && (userRolesLoading || sellerListingsLoading || filtersLoading) || !initialized) &&
        <div className='loaderCommon'>
          <LoaderCommon />
        </div>
      }
      {(hasListing) && <Navigate to="/" />}
      {(initialized &&
        ((!keycloak?.authenticated && !userRolesLoading && data && filtersData) ||
          (keycloak?.authenticated && userRoles && !hasListing))) &&
        (
          <div id="buyer-listing" className="findIsvcontainer programContainer sellerContainer">
            <SellerSecondMenu
              showSearch={true}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              handleSearch={handleSearch}
              handleGoToFavorite={handleGoToFavorite}
            />
            <div className="filterFindIsv leftTop hScroll">
              <div className="contentFilter">
                <div className="block sellerBuyer pb-0 border-0">
                  <div className="sellers active" onClick={() => goToSellerListing()}>
                    <a href="javascript:;">
                      <span className="icon-sellers"></span>Sellers
                    </a>
                  </div>
                  <div className="buyer" onClick={() => goToBuyerListing()}>
                    <a href="javascript:;">
                      <span className="icon-buyerList"></span>Buyers
                    </a>
                  </div>
                </div>
                {
                  shouldDisplayBlock ? (
                    <>
                      <div className="containerBtn block infoProgramContainer border-0">
                        <button className="btn btn-devinsider cancel" onClick={() => filterSellerListing()}>Filter</button>
                        <a className="resetFilter" onClick={() => resetSellerListing()}>
                          <span className="icon-refresh" />
                          Reset filter
                        </a>
                      </div>
                      <div className="block">
                        <h3 className={`title withChild ${isLocationOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsLocationOpen)}>HQ location</h3>
                        <div className="listCheck">
                          {
                            paginate(filtersData?.location, limitLocation)
                              ?.sort((a, b) => {

                                const countryA = ISO3166.getCountry(a) || "";
                                const countryB = ISO3166.getCountry(b) || "";
                                return countryA.localeCompare(countryB);
                              })
                              ?.map((el: any, index: any) => (
                                <div className="custom-control custom-checkbox orange" key={index}>
                                  <input
                                    type="checkbox"
                                    id={`location${index}`}
                                    className="custom-control-input"
                                    onChange={() => handleSelectLocation(el)}
                                    checked={
                                      selectedLocation?.find(
                                        (selected: any) => selected === el
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                  <label htmlFor={`location${index}`} className="custom-control-label">
                                    {ISO3166.getCountry(el)}
                                  </label>
                                </div>
                              ))
                          }
                          <SeeBtn
                            dataSource={filtersData?.location}
                            limit={limitLocation}
                            seeMoreOrLess={(p: string) => handleSeeLocation(p)}
                          />
                        </div>
                      </div>
                      <div className="block">
                        <h3 className={`title withChild ${isSolutionOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsSolutionOpen)}>Solution (Horizontal)</h3>
                        <div className="listCheck">
                          {
                            paginate(filtersData?.software_solution, limitSolution)?.sort()?.map((el: any, index: any) => (
                              <>
                                <div className="custom-control custom-checkbox orange" key={index}>
                                  <input
                                    type="checkbox"
                                    id={`solutions${index}`}
                                    className="custom-control-input"
                                    onChange={() => handleSelectSolution(el)}
                                    checked={
                                      selectedSolution?.find(
                                        (selected: any) => selected === el
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                  <label htmlFor={`solutions${index}`} className="custom-control-label">
                                    {el}
                                  </label>
                                </div>
                              </>
                            ))
                          }
                          <SeeBtn
                            dataSource={filtersData?.software_solution}
                            limit={limitSolution}
                            seeMoreOrLess={(p: string) => handleSeeSolution(p)}
                          />
                        </div>
                      </div>
                      <div className="block">
                        <h3 className={`title withChild ${isIndustryOpen ? 'open' : 'closed'}`} onClick={() => toggleFilterSection(setIsIndustryOpen)}>Industry (Vertical)</h3>
                        <div className="listCheck">
                          {
                            paginate(filtersData?.targeted_industry, limitIndustry)?.sort()?.map((el: any, index: any) => (
                              <>
                                <div className="custom-control custom-checkbox orange" key={index}>
                                  <input
                                    type="checkbox"
                                    id={`industries${index}`}
                                    className="custom-control-input"
                                    onChange={() => handleSelectIndustry(el)}
                                    checked={
                                      selectedIndustry?.find(
                                        (selected: any) => selected === el
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                  <label htmlFor={`industries${index}`} className="custom-control-label">
                                    {el}
                                  </label>
                                </div>
                              </>
                            ))
                          }


                        </div>
                      </div>
                    </>
                  ) : null
                }

              </div>

            </div>

            <div id="my-listing" className="container mx-auto minHeightwin sellerRight">
              <div className="listOfIsv listOfSeller scrollContainer">
                <div>
                  {(!userRoles || (userRoles && !userRoles?.isBuyer)) &&
                    <div className="aboutSection">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud exercitation ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat
                      nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                      sunt in culpa qui officia deserunt mollit anim id est laborum..

                      <div className="mt-3">
                        <a
                          className={`btn btn-devinsider save ${userRolesLoading ? ' disabled' : ''}`}
                          href="javascript:;"
                          onClick={() => handleEnroll()}
                        >
                          Enroll now!
                        </a>
                      </div>
                    </div>
                  }
                  {
                    <ul className="listSeller">
                      {searchInput.trim() !== '' ? (
                        (handleSearch()?.length > 0 ? handleSearch() : []).map((el: any, index: any) => (
                          <>
                            <li className={`list ${isOpportunistic(el) ? 'opportunistic' : ''} ${isPremium(el) ? 'premium' : ''}`.trim()} key={"logo"}>
                              <div className="logo">
                                <span className="icon-building1"></span>
                              </div>
                              <div className="desc" key={"desc"}>
                                <h2 className="title">
                                  {el?.stage} <span className="icon-star-off"></span>
                                </h2>
                                <div className="about"><span>{el?.company_reason}</span></div>
                                <a className={`btn btn-devinsider save ${userRolesLoading ? ' disabled' : ''}`} href="javascript:;" onClick={() => goToDetailListing(el?.id, el?.id_user)}>More details</a>
                                <ul>
                                  <li className="item" key={"location"}>
                                    <div>Location</div>
                                    <span className="ico icon-location"></span>
                                    <span className="txt">{ISO3166.getCountry(el?.location)}</span>
                                  </li>
                                  <li className="item" key={"founded"}>
                                    <div>Founded</div>
                                    <span className="ico icon-founded"></span>
                                    <span className="txt">{el?.founded}</span>
                                  </li>
                                  <li className="item" key={"companySize"}>
                                    <div>Company size</div>
                                    <span className="ico icon-compSize"></span>
                                    <span className="txt">{el?.company_size}</span>
                                  </li>
                                  <li className="item" key={"value_driver"}>
                                    <div>Top value driver</div>
                                    <span className="ico icon-topValue"></span>
                                    <span className="txt">{el?.value_driver}</span>
                                  </li>
                                  <li className="item" key={"valuation"}>
                                    <div>Valuation</div>
                                    <span className="ico icon-valuation"></span>
                                    <span className="txt">{el?.valuation}</span>
                                  </li>
                                  <li className="item" key={"profitability"}>
                                    <div>Profitability</div>
                                    <span className="ico icon-profitability"></span>
                                    <span className="txt">{el?.profitability}</span>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </>
                        ))
                      ) : (
                        sortedListings && sortedListings.map((el: any, index: any) => (
                          <li className={`list ${isOpportunistic(el) ? 'opportunistic' : ''} ${isPremium(el) ? 'premium' : ''}`.trim()} key={index}>
                            <div className="logo">
                              <span className="icon-building1"></span>
                            </div>
                            <div className="desc">
                              <h2 className="title">
                                {el?.stage} <span className="icon-star-off"></span>
                              </h2>
                              <div className="about"><span>{el?.company_reason}</span></div>
                              <a className="btn btn-devinsider" href="javascript:;" onClick={() => goToDetailListing(el?.id, el?.id_user)}>More details</a>
                              <ul>
                                <li className="item" key={"el_location"}>
                                  <div>Location</div>
                                  <span className="ico icon-location"></span>
                                  <span className="txt">{ISO3166.getCountry(el?.location)}</span>
                                </li>
                                <li className="item" key={"el_founded"}>
                                  <div>Founded</div>
                                  <span className="ico icon-founded"></span>
                                  <span className="txt">{el?.founded}</span>
                                </li>
                                <li className="item" key={"el_companySIze"}>
                                  <div>Company size</div>
                                  <span className="ico icon-compSize"></span>
                                  <span className="txt">{el?.company_size}</span>
                                </li>
                                <li className="item" key={"el_value_driver"}>
                                  <div>Top value driver</div>
                                  <span className="ico icon-topValue"></span>
                                  <span className="txt">{el?.value_driver}</span>
                                </li>
                                <li className="item" key={"el_valuation"}>
                                  <div>Valuation</div>
                                  <span className="ico icon-valuation"></span>
                                  <span className="txt">{el?.valuation}</span>
                                </li>
                                <li className="item" key={"el_profitability"}>
                                  <div>Profitability</div>
                                  <span className="ico icon-profitability"></span>
                                  <span className="txt">{el?.profitability}</span>
                                </li>
                              </ul>
                            </div>
                          </li>
                        ))

                      )}
                    </ul>}
                </div>
                <div className="pagginationCategory">
                  <div className="pagination"></div>
                </div>
              </div>
            </div>
            <EnrollBuyer
              show={enrollBuyer}
              handleOk={() => setEnrollBuyer(false)}
              continue={handleEnroll}
            />
          </div>
        )
      }
    </>
  );
}
